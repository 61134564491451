import gql from "graphql-tag";

export const financialApplicationFragment = gql`
  {
    id
    user {
      id
      firstName
      lastName
      email
      username
      dateOfBirth
    }
    highSchoolYear
    residenceState
    currentBalance
    earnedIncome
    hadJob
    numOfContributingParents
    peopleInHousehold
    numOfFamilyMembersInCollege
    bornedYearOfEldestParent
    adjustedGrossIncome
    liquidAssets
    assetEducationalAccounts
    parentsTaxForm
    incomeTaxPaid
    receivedFederalBenefits
    isParentDislocatedWorker
    parentsOwnedFarmOrLand {
      id
      isPrimaryHome
      valueOfFarmOrLand
      mortgagePayoff
      isHome
    }
    schoolList {
      id
      school {
        id
        name
        images {
          id
          url
        }
        applicationType
      }
      user {
        id
      }
      isInStateSchool
      isOutStateSchool
      isPrivateSchool
      isCalStateSchool
    }
    visionList {
      id
      school {
        id
        name
        images {
          id
          url
        }
        applicationType
      }
      user {
        id
      }
      isInStateSchool
      isOutStateSchool
      isPrivateSchool
      isCalStateSchool
    }
    parentContribution {
      id
      iraDeductiblePayments
      taxExemptInterestIncome
      untaxedPortionsIRA
      untaxedPortionsPensions
      foreignIncomeExclusion
      taxableCollegeGrant
      educationCredits
      federalWorkStudy
      annualChildSupportReceived
    }
    studentContribution {
      id
      iraDeductiblePayments
      taxExemptInterestIncome
      untaxedPortionsIRA
      untaxedPortionsPensions
      foreignIncomeExclusion
      taxableCollegeGrant
      educationCredits
      federalWorkStudy
      incomeTaxPaid
      netWorthCurrentInvestments
    }
  }
`;
