<template>
	<div class="border-t flex justify-center items-center px-4 xl:px-0">
		<div class="max-w-7xl h-10 w-full grid grid-cols-1 md:grid-cols-7">
			<div class="col-span-4">
				<div class="flex items-center space-x-6 justify-center sm:justify-between md:justify-start space-x-0 md:space-x-7 flex-wrap">
					<Link v-for="(item, i) in isLoggedIn ? navigation : loginNavigation" :key="item" size="sm" weight="medium" color="gray-600" hoverColor="primary" :content="item.name" :url="item.href" class="pb-0 pt-8 md:py-8"/>
				</div>
        <div class="col-span-3 sm:hidden flex justify-center md:justify-end space-x-3 mt-7 md:pb-0">
          <a href="https://www.facebook.com/TrustedAid/"><img src="../../../../assets/images/facebook.png" class="w-5 h-5 object-contain"></a>
          <a href="https://twitter.com/TrustedAid"><img src="../../../../assets/images/twitter.png" class="w-5 h-5 object-contain"></a>
          <a href="https://www.linkedin.com/company/mytrustedaid"><img src="../../../../assets/public/mail/linkedin.png" class="w-5 h-5 object-contain"></a>
          <a href="https://www.instagram.com/trustedaid/"><img src="../../../../assets/images/icon_instagram.png" class="w-5 h-5 object-contain"></a>
        </div>
				<Text :content="description" size="xs" color="gray-400" class="text-center sm:text-left md:text-start leading-5 mt-7 md:mt-0 mb-8 md:mb-12"/>
			</div>
			<div class="hidden col-span-3 sm:flex justify-center md:justify-end space-x-3 mt-8 pb-10 md:pb-0">
				<a href="https://www.facebook.com/TrustedAid/"><img src="../../../../assets/images/facebook.png" class="w-5 h-5 object-contain"></a>
				<a href="https://twitter.com/TrustedAid"><img src="../../../../assets/images/twitter.png" class="w-5 h-5 object-contain"></a>
				<a href="https://www.linkedin.com/company/mytrustedaid"><img src="../../../../assets/public/mail/linkedin.png" class="w-5 h-5 object-contain"></a>
				<a href="https://www.instagram.com/trustedaid/"><img src="../../../../assets/images/icon_instagram.png" class="w-5 h-5 object-contain"></a>
			</div>
		</div>
	</div>
</template>

<script>
import Button from '../../../atoms/Button/Button.vue'
import Link from '../../../atoms/Link/Link.vue'
import Text from '../../../atoms/Text/Text.vue'
/**
 * - Use it to show a simple footer with social icons
 */
export default {
	components: {
		Button, Link, Text
	},
	props: {
		/**
		 * navigation list
		 */
		navigation: {
			type: Array,
			default: () => []
		},
		loginNavigation: {
			type: Array,
			default: () => []
		},
		/**
		 * description
		 */
		description: {
			type: String,
			default: ''
		},
		isLoggedIn: {
			type: Boolean,
			default: false,
		}
	}
}
</script>
