/*
*
*/
export const getTotalGroups = async ({state, effects}, data) => {
  let options = {}
  if (data && data.options) options = data.options
  const {groups} = await effects.gql.queries.groups(options)

  state.company.totalRecords = groups ? groups.length : 0
}

/*
*
*/
export const getCompanies = async ({state, effects}, data) => {
  console.log('action getCompanies...')
  try {
    let options = {}
    if (!data) {
      options = {
        first: state.company.companyPerPage,
        skip: (state.company.activePage - 1) * state.company.companyPerPage
      }
    } else {
      if (data && data.all) options = {}
      else {
        options = data;
        if (!data.first) options.first = state.company.companyPerPage;
        if (!data.skip) options.skip = (state.company.activePage - 1) * state.company.companyPerPage;
      }
    }
    //
    const {companies} = await effects.gql.queries.companies(options)
    if (data && data.getValues) return companies
    else state.company.companies = companies
  } catch (e) {
    console.log(e, 'getCompanies errors');
  }
}

/*
*
*/
export const saveCompany = async ({effects}, variables) => {
  try {
    const {saveCompany} = await effects.gql.mutations.saveCompany(variables);
    return saveCompany
  } catch (e) {
    console.log(e);
  }
};

/*
*
*/
export const onChangePage = async ({state}, page) => {
  state.company.activePage = page
}

/*
*
*/
export const onGroupAdded = ({state}, data) => {
  state.company.push(data)
}

export const getGroupByName = async ({effects, actions}, data) => {
  try {
    const resp = await effects.gql.queries.groups(data);
    return resp;
  } catch (e) {
    actions.alert.showError({
      message: e.response && e.response.errors && e.response.errors.length ? e.response.errors[0]['message'] : 'Error',
      title: 'Get Group By Name'
    });
  }
}

export const setNewStoryline = ({state}, data) => {
  state.newStoryline = data;
}
