import gql from 'graphql-tag';
import { cartFragment } from './fragments';

export const saveCart = gql`
  mutation saveCart(
    $userId: String!
    $cartId: String
    $eventId: String
    $orderId: String
    $deleteCart: Boolean
    $addItems: [CartItemInput!]
    $updateItems: [CartItemInput!]
    $removeItems: [CartItemInput!]
    $tipAmount: Float
    $findMarketplace: Boolean
    $tipType: String) {
      
    saveCart(userId:$userId cartId:$cartId eventId:$eventId orderId: $orderId deleteCart:$deleteCart addItems:$addItems updateItems:$updateItems findMarketplace: $findMarketplace
      removeItems:$removeItems tipAmount:$tipAmount tipType:$tipType
    ) ${cartFragment}
  }
`;

export const deleteCart = gql`
  mutation deleteCart($where: CartWhereUniqueInput) {
    deleteCart(where: $where) ${cartFragment}
  }
`;

export const checkForPromo = gql`
  mutation checkForPromo(
    $userId: String
    $code: String
    $tab: Json
    $cart: Json
    $targetProductId: String
    $currentItem: Json
    $itemCost: Float
    $posItemPricePerUnit: Float
    $items: Json
    $modifiers: Json
    $closedCampaigns: [String]
    $foundCampaigns: [String]
    $originatingState: String
  ) {
    checkForPromo(
      userId: $userId
      code: $code
      tab: $tab
      cart: $cart
      targetProductId: $targetProductId
      currentItem: $currentItem
      itemCost: $itemCost
      posItemPricePerUnit: $posItemPricePerUnit
      items: $items
      modifiers: $modifiers
      closedCampaigns: $closedCampaigns
      foundCampaigns: $foundCampaigns
      originatingState: $originatingState
    )
  }
`;
