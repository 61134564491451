import { Settings } from '../../settings.js'

export const onInitialize = async ({ actions, effects, state }, instance) => {
  effects.gql.initialize({
    endpoint: Settings.gqlServer.url, // query and mutation options
    headers: () => ({
      authorization: `Bearer ${state.authToken}`,
      privateKey: Settings.appKey
    })
  }, {
    endpoint: Settings.gqlServer.ws, // subscription options
  });

  console.log('Overmind Initialization...');
  effects.router.initialize(actions); // init state
  state.currentUser = await effects.storage.getData('currentUser');
  state.currentCart = await effects.storage.getData('currentCart');
  state.order = await effects.storage.getData('order');
  state.product = await effects.storage.getData('product');
  state.wallet = await effects.storage.getData('wallet');
  state.token = await effects.storage.getData('token');
  instance.reaction(
    ({currentUser, currentCart, order, product, wallet, token}) => {
      return {currentUser: currentUser, currentCart, order, product, wallet, token}
      },
    async ({currentUser, currentCart, order, product, wallet, token}) => {
      await effects.storage.saveData(order, 'order');
      await effects.storage.saveData(currentUser, 'currentUser');
      await effects.storage.saveData(currentCart, 'currentCart');
      await effects.storage.saveData(product, 'product');
      await effects.storage.saveData(token, 'token');
      await effects.storage.saveData(wallet, 'wallet');
    },
    {
      nested: false,
    }
  );
};
