const axios = require("axios");
import { Settings } from "../settings";

if (Settings.environment == "dev") {
  (function () {
    var originalLog = console.log;

    console.log = function (txt) {
      const sendParams = {};
      sendParams.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        publicApi: true,
      };

      axios.post(
        Settings.gqlServer.buildUrl,
        {
          query: `mutation writeStreamLog($log: Json, $errors: Json $saveToDb: Boolean) {
                writeStreamLog(log: $log errors: $errors  saveToDb: $saveToDb)
            }`,
          variables: {
            log: txt,
          },
        },
        sendParams
      );

      originalLog.apply(console, arguments);
    };
  })();
}
