import {keyBy, isEmpty} from 'lodash';

/*
*
*/
export const getMediaCollections = async ({state, effects}, data) => {
  console.log('action getMediaCollections...');

  try {

    let options = {};

    if (isEmpty(data)) {
      options = {
        first: state.media.mediaPerPage,
        skip: (state.media.activePage - 1) * state.media.mediaPerPage,
      };
    } else {
      options = data;
      if (!data.first) {
        options.first = state.media.mediaPerPage;
      }
      if (!data.skip) {
        options.skip = (state.media.activePage - 1) * state.media.mediaPerPage;
      }
    }

    console.log(options, 'getMediaCollection options');

    const {getMediaCollections} = await effects.gql.queries.getMediaCollections(options);

    console.log(getMediaCollections, 'getMediaCollections results');

    state.media.mediaCollections = keyBy(getMediaCollections, 'id');

  } catch (e) {
    console.log(e, 'getMediaCollections errors');
  }
};
