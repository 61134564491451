import gql from 'graphql-tag';
import {schoolFragment} from "@/store/effects/gql/school/fragments";
import {companyFragment} from "@/store/effects/gql/company/fragments";

export const PaymentMethod = `
{
  id type expirationDate cardType last4 isValid isDefault createdAt methodType isValid image
  vendors { name token callbackUrl redirectUrl isValid checkoutUrl }
}
`;
/*
*
*/
export const userFragment = gql`{
 id
  balance
  bio
  appVersion
  needToUpgrade
  timezoneOffset
  chatId
  playerId
  gender
  timezone
  firstName
  firstNameLower
  middleName
  middleNameLower
  lastName
  lastNameLower
  fullName
  fullNameLower
  dateOfBirth
  phones{id number}
  email
  avatar
  braintreeCustomerId
  createdAt
  updatedAt
  site { id name address address2 city state postalCode country phones{id number} googlePlacesId gps{lat lon} }
  sites { id name address address2 city state postalCode country phones{id number} googlePlacesId gps{lat lon}}
  paymentMethods${PaymentMethod}
  groups { id name }
  orders { id }
  productSubscriptions{id name description isActive product{id name} nextRenewalDate}
  
}`;

/*
*
*/
export const AuthPayLoad = gql`
{
  user${userFragment}
  verificationCode
}
`;

/*
*
*/
export const contactFragment = gql`
{
  id
  name
  subject
  comment
  emails
  phones
}
`

