<template>
  <div id="app">
<!--    <vue-confirm-dialog></vue-confirm-dialog>-->
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
const default_layout = 'default';

export default {
  name: "app",
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    }
  },
}
</script>

<style>
/*@font-face {*/
/*  font-family: "Montserrat";*/
/*  src: local("Montserrat"),*/
/*   url(./assets/fonts/Montserrat-Regular.ttf) format("truetype");*/
/*}*/
</style>
