import gql from 'graphql-tag';

export const cartFragment = gql`{
  id subtotal tipPercentage tip dueNow tax total discount delivery shipping isPending createdAt updatedAt
  event { id name days { id name startDate } }
  items { id name description priceEach quantity deliverBy rating isReviewed
    modifiers { id name sortOrder ingredient{id name}
    }
    campaign { id discountType discountAmount ads{id images{id}} maxItemsPer }
    deliverTo { id name address address2 state city postalCode country phones{id number} }
    product { id name description isAddOn
      pricing { id type retailPrice }
      images{id url}
      tasks { 
        id
        ingredient{id name}
      }
    }
  }  
}`
