import {
  surveyWithSteps,
  surveyWithStepsForSAI,
} from "../../.storybook/sampleData";
import logo from "../assets/public/data/landing-data.json";
import logo1 from "../assets/public/lottie/landing/animationData3.json";
import calculateData from "../assets/public/lottie/calculate/calculateData.json";

export const avatarSizes = {
  6: "xs",
  8: "sm",
  10: "medium",
  12: "lg",
  14: "xl",
};

const header = {
  navigation: [
    {
      name: "Home",
      href: "#",
    },
    {
      name: "Pricing",
      href: "/#/pricing",
    },
    {
      name: " About",
      href: "/#/about",
    },
    {
      name: "My Dashboard",
      href: "/#/dashboard",
    },
    {
      name: "Sign out",
      href: "/#/logout",
    },
  ],
  loginNavigation: [
    {
      name: "Home",
      href: "#",
    },
    {
      name: "Pricing",
      href: "/#/pricing",
    },
    {
      name: " About",
      href: "#/about",
    },
    // {
    //   name: 'FAQ',
    //   href: '#'
    // },
    {
      name: "Login",
      href: "/#/login",
    },
    {
      name: "Sign Up",
      href: "/#/register",
    },
  ],
  logo: "https://faid-media.s3.us-west-1.amazonaws.com/logos/faid-logo-teal.png",
};

const footer = {
  navigation: [
    {
      name: "Home",
      href: "#",
    },
    {
      name: "Pricing",
      href: "/#/pricing",
    },
    {
      name: " About",
      href: "/#/about",
    },
    // {
    //   name: 'FAQ',
    //   href: '#'
    // },
    {
      name: "Sign out",
      href: "/#/logout",
    },
  ],
  loginNavigation: [
    {
      name: "Home",
      href: "#",
    },
    {
      name: "Pricing",
      href: "/#/pricing",
    },
    {
      name: " About",
      href: "#/about",
    },
    // {
    //   name: 'FAQ',
    //   href: '#'
    // },
    {
      name: "Login",
      href: "/#/login",
    },
    {
      name: "Sign Up",
      href: "/#/register",
    },
  ],
  description:
    "Our mission at FAID is to increase the accessibility of higher education to diverse communities by connecting students with institutions that give the most FREE MONEY to graduate DEBT FREE.\n It provides the most accurate prediction of financial aid on the market, but does not claim to provide exact award letter details. FAID is not liable for discrepancies between our financial aid award predictions and the actual award packages given to the student by colleges.\nCopyright @ 2023 FAID. All Rights Reserved.",
  socials: [
    {
      image: "facebook.png",
      href: "https://www.facebook.com/FAID/",
    },
    {
      image: "twitter.png",
      href: "https://twitter.com/FAID",
    },
    {
      image: "linkedin.png",
      href: "https://www.linkedin.com/company/mytrustedaid",
    },
    {
      image: "instagram.png",
      href: "https://www.instagram.com/trustedaid/",
    },
  ],
};

export const c = {
  login: {
    form: {
      title: "Login",
      description: `Don't have an account? Create a <a class="text-primary underline font-semibold" @click="$emit('goRegister')">profile</a> now`,
      forms: [
        {
          label: "EMAIL ADDRESS",
          type: "email",
          value: "",
          id: "email",
        },
        {
          label: "PASSWORD",
          type: "password",
          value: "",
          id: "password",
        },
      ],
      btnText: "Log In",
    },
    texts: [
      "Our mission at FAID is to increase the accessibility of higher education to diverse communities by connecting students with institutions that give the most FREE MONEY to graduate DEBT FREE.\n It provides the most accurate prediction of financial aid on the market, but does not claim to provide exact award letter details. FAID is not liable for discrepancies between our financial aid award predictions and the actual award packages given to the student by colleges.",
      "Copyright © 2023 FAID. All Rights Reserved.",
    ],
  },
  newPassword: {
    form: {
      title: "Reset Password",
      description: `Enter your password`,
      forms: [
        {
          label: "PASSWORD",
          type: "password",
          value: "",
          id: "password",
          description:
            "Your password must be at least 8 characters and contain at least one capital letter, one symbol, and one number",
          isNotForgot: true,
        },
      ],
      btnText: "Reset",
    },
  },
  resetPassword: {
    form: {
      title: "Reset Password",
      description: "Enter your email address to reset your password",
      forms: [
        {
          label: "EMAIL ADDRESS",
          type: "email",
          value: "",
          id: "email",
        },
      ],
      btnText: "Reset Password",
    },
    texts: [
      "Our mission at FAID is to increase the accessibility of higher education to diverse communities by connecting students with institutions that give the most FREE MONEY to graduate DEBT FREE.\n It provides the most accurate prediction of financial aid on the market, but does not claim to provide exact award letter details. FAID is not liable for discrepancies between our financial aid award predictions and the actual award packages given to the student by colleges.",
      "Copyright © 2023 FAID. All Rights Reserved.",
    ],
  },
  register: {
    form: {
      title: "Create your profile",
      description:
        'Already have a profile? <a class="text-primary underline font-semibold">Log in here</a>',
      forms: [
        {
          label: "FIRST NAME",
          type: "text",
          placeholder: "e.g. John",
          value: "",
        },
        {
          label: "LAST NAME",
          type: "text",
          placeholder: "e.g. Smith",
          value: "",
        },
        {
          label: "EMAIL ADDRESS",
          type: "email",
          placeholder: "e.g. julie@example.com",
          value: "",
        },
        {
          label: "ROLE",
          type: "select",
          options: [
            { name: "Student", value: "Student" },
            { name: "Parent", value: "Parent" },
            { name: "Teacher", value: "Teacher" },
            { name: "School Administrator", value: "School Administrator" },
            { name: "Other", value: "Other" },
          ],
          value: null,
        },
        {
          label: "PASSWORD",
          type: "password",
          placeholder: "More than 6 characters",
          value: "",
        },
      ],
      btnText: "Create your Profile Now",
      confirmLabel:
        'I agree to the <a class="text-primary">Terms of Use</a> and <a class="text-primary">Privacy Policy</a>',
    },
    texts: [
      "Our mission at FAID is to increase the accessibility of higher education to diverse communities by connecting students with institutions that give the most FREE MONEY to graduate DEBT FREE.\n It provides the most accurate prediction of financial aid on the market, but does not claim to provide exact award letter details. FAID is not liable for discrepancies between our financial aid award predictions and the actual award packages given to the student by colleges.",
      "Copyright © 2023 FAID. All Rights Reserved.",
    ],
  },
  terms: {
    header,
    policyHeader: {
      type: "terms",
      url: "#",
      lastUpdatedAt: "August 28, 2018",
      content:
        'FAID, Inc. and its affiliates ("<b>FAID</b>," "<b>we</b>" or "<b>us</b>") provides a unique, ' +
        "subscription-based platform that recommends colleges and universities to students based upon the amount of " +
        "financial aid they are likely to receive, including its website located at www.faid.co " +
        '(the "<b>Site</b>"), related mobile applications (the “<b>Mobile Apps</b>”) and services (the "<b>FAID Services</b>"). ' +
        "The Site, the Mobile Apps and the FAID Services are collectively referred to as the “<b>FAID Platform.</b>”<br/><br/>" +
        "THIS IS A LEGAL AGREEMENT BETWEEN YOU AND FAID WHICH GOVERNS YOUR USE OF THE FAID PLATFORM. Your use of the " +
        'FAID Platform is subject to your acceptance of and adherence to these Terms of Use (the "<b>Terms of Use</b>"). ' +
        'The terms "<b>you</b>", "<b>your</b>” and "<b>user</b>" shall refer to all individuals that access and use the FAID Platform.<br/><br/>' +
        "By indicating your agreement to these Terms of Use and/or accessing or using FAID Platform, you are accepting " +
        "these Terms of Use and you represent and warrant that you have the right, authority, and capacity to enter " +
        "into these Terms of Use. By using the FAID Platform, you represent and warrant that you are at least 14 " +
        "years old. If you are under the age of 14, do not access and/or use the FAID Platform. If you do not agree " +
        "with all of the provisions of the Terms of Use, do not access and/or use the FAID Platform. Please consult " +
        "our Privacy Policy for a description of our privacy practices and policies, including how: (i) we collect " +
        "and handle personal and financial information; (ii) you may retain control of content that you have generated; " +
        "and (iii) how you may transfer the content you have generated to an account of your choosing. By accepting " +
        "these Terms of Use you consent to allow FAID to communicate with you electronically regarding the FAID Platform. " +
        "FAID will, within a commercially reasonable time, notify you (including parent, or legal guardian and appropriate " +
        "educational institution) in the event of a data breach or unauthorized disclosure affecting any of your records. " +
        "FAID will not use any records or information in violation of our Privacy Policy or these Terms of Use.<br/><br/>" +
        "NOTE: THESE TERMS OF USE CONTAINS A DISPUTE RESOLUTION AND ARBITRATION PROVISION, INCLUDING CLASS ACTION " +
        "WAIVER THAT AFFECTS YOUR RIGHTS UNDER THESE TERMS OF USE AND WITH RESPECT TO DISPUTES YOU MAY HAVE WITH FAID. " +
        "YOU MAY OPT OUT OF THE BINDING INDIVIDUAL ARBITRATION AND CLASS ACTION WAIVER AS PROVIDED BELOW.",
    },
    policyItems: [
      {
        title: "The FAID Platform",
        subContents: [
          {
            subContent:
              "The FAID Platform searches for and locates hidden financial aid and grant data found " +
              "across the web, and produces financial award estimates for each school a student wishes to " +
              "apply to, all based on each family’s unique financial situation. The FAID Platform allows " +
              "individual users to create an account, upload information and receive feedback on how much " +
              "financial aid a particular college or university will provide, builds a dashboard, calculate " +
              "the total cost to attend a particular school, and provides other services and content, such " +
              "as tips on how to maximize financial awards and uncover opportunities for financial assistance. " +
              "We also provide a resource center with helpful reading material, a glossary of terms and a " +
              "calendar of upcoming deadlines. Any records, including student records, that is derived from " +
              "an educational entity is the property of and under the control of such educational entity. " +
              "Our Privacy Policy describes how you (or a parent or legal guardian) may review personally " +
              "identifiable information and correct erroneous information.",
          },
        ],
      },
      {
        title: "Modifications to the Terms of Use",
        subContents: [
          {
            subContent:
              "We may make changes to the Terms of Use from time to time. A link to the most current " +
              'Terms of Use will be available on the Site and we will indicate the date of the "Latest Update"' +
              " at the top of the Terms of Use. We will also place a special notice on the FAID Services or " +
              "communicate material changes by email. Your continued use of the FAID Platform following the " +
              "posting of such changes constitutes your acceptance of the amended Terms of Use.",
          },
        ],
      },
      {
        title: "Read Our Privacy Policy",
        subContents: [
          {
            subContent:
              "Any information that FAID collects through your use of the FAID Platform is subject to " +
              "FAID’s Privacy Policy, which is incorporated and made part of these Terms of Use. Our Privacy " +
              "Policy can be found at www.faid.co/privacy, and it explains how we collect, use, " +
              "and disclose information that pertains to your privacy. FAID used commercially reasonable " +
              "standards, including designation and training of individuals directly responsible for the " +
              "handling of information collected in accordance with our Privacy Policy and any amendments " +
              "thereto, to ensure the privacy of our users. FAID does not use personally identifiable " +
              "information in student records from educational institutions for targeted advertising.",
          },
        ],
      },
      {
        title: "Who Can Use the FAID Platform?",
        subContents: [
          {
            subContent:
              "An account to use the FAID Platform can only be opened by an adult or an individual 14 " +
              "to 18 years old that possesses legal parental or guardian consent, and that are fully able and " +
              "competent to enter this Agreement and comply with the terms of this Agreement. You are solely " +
              "responsible for your use of the FAID Platform and whether and how your child or children use " +
              " FAID Platform, as well as whom, if anyone, can view your child’s or children’s information " +
              "and data when using the FAID Platform.",
          },
        ],
      },
      {
        title: "Creating an Account",
        subContents: [
          {
            subContent:
              "Full use of the FAID Platform requires that you create an account by providing us with " +
              "a valid email address and strong password. You are responsible for all activity that occurs in " +
              "association with your account. FAID is not liable for any loss or damages caused by your " +
              "failure to maintain the confidentiality of your account credentials.<br/><br/>" +
              "We may need to contact you about your use of the FAID Platform. These communications are part " +
              "of the FAID Services and you may not opt-out from receiving them. You can manage and opt-out " +
              "from receiving other communications and keep your email address up-to-date from your account settings.",
          },
        ],
      },
      {
        title: "Faculty Accounts",
        subContents: [
          {
            subContent:
              "For faculty who have been assigned an account by their educational institution, the " +
              "following additional terms will apply to your faculty account. Faculty users may be provided " +
              "access to certain information of other users of the FAID Platform. If you are a faculty " +
              "account user, you agree to keep all user information accessed by you in the FAID Platform " +
              "confidential and not to use or disclose such information without the consent of FAID except " +
              "as part of your institution’s legitimate and intended use of the FAID Platform.",
          },
        ],
      },
      {
        title: "Necessary Equipment",
        subContents: [
          {
            subContent:
              "Full use of the FAID Platform is dependent upon your use of a computer with adequate " +
              "software or a supported mobile device and Internet access. The maintenance and security of " +
              "this equipment may influence the performance of the FAID Platform and it is your " +
              "responsibility to ensure your equipment’s functionality.",
          },
        ],
      },
      {
        title: "Your Information on the FAID Platform",
        subContents: [
          {
            subContent:
              "Any personal information (“Student Data”) that you provide FAID through your use of the " +
              "FAID Platform is subject to FAID’s Privacy Policy, which can be found at www.faid.co/privacy. " +
              "We treat your Student Data as confidential and do not knowingly share your information with third " +
              "parties other than as described in this Terms of Use and our Privacy Policy. You grant FAID " +
              "a non-exclusive, transferable, worldwide, royalty-free right and license to store, access, " +
              "view, and use your Student Data in order to provide the FAID Services. The information you " +
              "provide FAID may also be securely viewed or accessed by certain faculty users from your " +
              "educational institution with a legitimate need to do so in order to assist with your use " +
              "of the FAID Services. You grant such faculty users a non-exclusive, transferable, worldwide, " +
              "royalty-free right and license to access, view, and use your Student Data to assist with the " +
              "FAID Services and as may be permitted through the functionality of the FAID Platform. By posting, " +
              "uploading, inputting, providing or submitting information to the FAID Platform, you represent " +
              "and warrant that you own such information or otherwise have all the rights or consents of " +
              "third parties necessary for you to submit such information.",
          },
        ],
      },
      {
        title: "Content on the FAID Platform",
        subContents: [
          {
            subContent:
              "You may post various content to the FAID Platform, including data, photos, comments and " +
              "other content (“<b>Your Content</b>”). Your Content does not include your privately submitted " +
              "Student Data. You retain all rights to Your Content that you accumulate and post to the FAID " +
              "Platform. By making Your Content publicly available to other users on or through the FAID " +
              "Platform, you hereby grant FAID a non-exclusive, transferable, sublicensable, worldwide, " +
              "royalty-free license to use, copy, modify, publicly display, publicly perform and distribute " +
              "Your Content in connection with operating and providing the FAID Platform (“<b>Your License</b>”). " +
              "You are responsible for Your Content. You represent and warrant that you own Your Content or " +
              "that you have all rights necessary to grant us Your License. You also represent and warrant " +
              "that Your Content and the use and provision of Your Content on the FAID Platform will not: (a) " +
              "infringe, misappropriate or violate a third party’s patent, copyright, trademark, trade secret, " +
              "moral rights or other intellectual property rights, or rights of publicity or privacy; (b) " +
              "violate, or encourage any conduct that would violate, any applicable law or regulation or would " +
              "give rise to civil liability; (c) be fraudulent, false, misleading or deceptive; (d) be " +
              "defamatory, obscene, pornographic, vulgar or offensive; (e) promote discrimination, bigotry, " +
              "racism, hatred, harassment or harm against any individual or group; (f) be violent or threatening " +
              "or promote violence or actions that are threatening to any person or entity; or (g) promote " +
              "illegal or harmful activities or substances.<br/><br/>" +
              "You also agree that Your Content will comply with the following FAID Platform guidelines:<br/><br/>" +
              "<ul><li>Be respectful of the opinions of others. Even though you might not agree with someone, " +
              "that doesn't mean they are wrong or deserve to be belittled. Remember that what works for " +
              "you may not work for everyone else. Give everyone the same courtesy you would expect in return.</li>" +
              "<li>Do not post profane or explicit content.</li>" +
              "<li>Do not post pictures that might be considered inappropriate.</li>" +
              "<li>Do not post communications that could be interpreted as threatening or harassing.</li>" +
              "<li>Do not post, advertise, or promote products or services commercially.</li></ul>",
          },
        ],
      },
      {
        title: "FAID’s Rights and Intellectual Property",
        subContents: [
          {
            subContent:
              "For purposes of these Terms of Use, “<b>FAID Content</b>” means and includes any text, " +
              "graphics, images, music, software, audio, video, works of authorship of any kind, and " +
              "information or other materials that are posted, generated, provided or otherwise made " +
              "available through the FAID Platform to you. Except for Your Content, the FAID Content, the " +
              "FAID Platform and its underlying technology are protected by copyright, trademark, patent, " +
              "intellectual property and other laws of the United States and foreign countries. You agree " +
              "not to remove, change or obscure any copyright, trademark, service mark or other proprietary " +
              "rights notices incorporated in or accompanying the FAID Platform.",
          },
        ],
      },
      {
        title: "Use of the FAID Platform",
        subContents: [
          {
            subContent:
              "The FAID Platform and the FAID Content are intended for your personal, non-commercial use. " +
              "FAID hereby grants you a limited, non-exclusive, non-transferable, non-sublicensable license to " +
              "access, view and use the FAID Content and FAID Platform for your personal, non-commercial use. " +
              "This license is provided solely for your personal use and enjoyment of the FAID Services as " +
              "permitted in these Terms of Use. You will not use, copy, adapt, modify, prepare derivative " +
              "works based upon, distribute, license, sell, transfer, publicly display, publicly perform, " +
              "transmit, broadcast or otherwise exploit the FAID Content and the FAID Platform or any portion " +
              "thereof, except as expressly permitted in these Terms of Use. No licenses or rights are granted " +
              "to you by implication or otherwise under any intellectual property rights owned or controlled " +
              "by FAID or its licensors, except for the licenses and rights expressly granted in these Terms " +
              "of Use.<br/><br/>Except to the extent permitted by law, you may not perform, attempt to perform, " +
              "or encourage or assist others in performing any of the following while accessing or using the FAID Platform:<br/>" +
              "(1) use, display, mirror or frame the FAID Platform or FAID Content or any individual element " +
              "within the FAID Platform or FAID Content, FAID’s name, any FAID trademark, logo or other " +
              "proprietary information, or the layout and design of any page or form contained on a " +
              "page, without FAID’s express written consent;<br/>" +
              "(2) access or tamper with non-public areas of the FAID Platform, FAID’s computer systems, " +
              "or the technical delivery systems of FAID’s providers;<br/>" +
              "(3) test the vulnerability of any FAID system or breach any security or authentication measures;<br/>" +
              "(4) circumvent any technological measure implemented by FAID or any of FAID’s providers or any " +
              "other third party (including another user) to protect the FAID Platform or FAID Content; or<br/>" +
              "(5) modify, decompile, disassemble, reverse engineer, tamper with or otherwise attempt to " +
              "derive the source code of the FAID Platform or any software that FAID provides to you.",
          },
        ],
      },
      {
        title: "Enforcement Rights",
        subContents: [
          {
            subContent:
              "We are not obligated to monitor access or use of the FAID Platform, FAID Content, or " +
              "Your Content or to review or edit any FAID Content or Your Content, but we have the right to " +
              "do so for the purpose of operating the FAID Platform, to ensure compliance with these Terms of " +
              "Use, and to comply with applicable law or other legal requirements. We may consult with and " +
              "disclose unlawful conduct to law enforcement authorities; and pursuant to valid legal process, " +
              "we may cooperate with law enforcement authorities to prosecute users who violate the law. " +
              "We reserve the right (but are not required) to remove or disable access to the FAID Platform, " +
              "any FAID Content, or Your Content at any time and with or without notice, and at our sole " +
              "discretion, if we determine that the FAID Content, Your Content, or your use of the FAID Platform " +
              "is objectionable or in violation of these Terms of Use. We have the right to investigate " +
              "violations of these Terms of Use and any conduct that affects the FAID Platform.",
          },
        ],
      },
      {
        title: "The FAID Platform is Used at Your Own Risk",
        subContents: [
          {
            subContent:
              "Our goal is to provide helpful and accurate information on the FAID Platform, but we " +
              "make no endorsement, representation or warranty of any kind about any FAID Content, " +
              "information, services or recommendations.<br/><br/>The information provided by the FAID " +
              "Platform, including the FAID Content, is for educational and informative purposes only, " +
              "and is not to be interpreted as a recommendation for a specific product, or course of " +
              "action. In addition, while the FAID Platform frequently updates the content it provides, " +
              "information changes rapidly, and therefore, some information may be out of date. Although " +
              "our estimations and models are based on reliable sources and data, the projected grants, " +
              "costs, and other college financial information provided to you through the FAID Platform " +
              "are estimates only and are not representations or guarantees of the actual grants you may " +
              "receive or actual costs a school may require, which may vary from our estimations.<br/><br/>" +
              "We are not responsible for the accuracy, reliability, effectiveness, or correct use of " +
              "information you receive through the FAID Platform. If you rely on any FAID Content or the " +
              "FAID Platform, you do so solely at your own risk.",
          },
        ],
      },
      {
        title: "Digital Millennium Copyright Act - Copyright Policy",
        subContents: [
          {
            subContent:
              "FAID respects other parties’ intellectual property rights and copyright law, and " +
              "expects its users to do the same. FAID reserves the right, in its sole discretion, with or " +
              "without notice, to terminate account holders who infringe the rights of copyright holders. " +
              "Please see FAID’s DMCA/Copyright Policy, which is incorporated and made part of these Terms of Use.",
          },
        ],
      },
      {
        title: "Idea Submissions",
        subContents: [
          {
            subContent:
              "FAID is always pleased to hear from its community. However, neither FAID, nor any of its " +
              "employees, may accept or consider any unsolicited ideas, including ideas for new or improved " +
              "products or services, new marketing campaigns or product or service names. Therefore, please " +
              "do not submit any such unsolicited ideas in any form to FAID or any of its employees. This " +
              "policy is necessary to avoid potential misunderstandings or disputes that may arise if our " +
              "new products or services or marketing campaigns were to appear similar to an idea submitted " +
              "by you.<br/><br/>If, despite our policy, you still submit an idea to us, then regardless of " +
              "anything contained in your submission you agree that the following shall apply: (i) we shall " +
              "have no obligation to review the submission; (ii) the submission and its contents shall " +
              "automatically become the property of FAID without any compensation to you; (iii) we may " +
              "consider the submission and its contents to be non-confidential and non-proprietary; and " +
              "(iv) we may redistribute or use (for commercial purposes or otherwise) the submission and " +
              "its contents for any purpose and in any way, without any compensation to you " +
              "(collectively, the “<b>Terms and Conditions</b>”).",
          },
        ],
      },
      {
        title: "Feedback",
        subContents: [
          {
            subContent:
              "If you or anyone else provides us (at our request or otherwise) a comment or suggestion " +
              "regarding our products or services (including our Mobile Applications) through any means, " +
              "these Terms and Conditions shall apply thereto.",
          },
        ],
      },
      {
        title: "Contests And Giveaways",
        subContents: [
          {
            subContent:
              "Additional terms and conditions may apply to contests, giveaways and other promotions " +
              "sponsored by FAID and its partners. It is your responsibility to carefully review those " +
              "terms and conditions.",
          },
        ],
      },
      {
        title: "Alerts and Notifications",
        subContents: [
          {
            subContent:
              "As part of your use of the FAID Platform, you may receive notifications, text messages, " +
              "alerts, or emails. You agree to the receipt of these communications. You are responsible for " +
              "any messaging or data fees you may be charged by your wireless carrier.",
          },
        ],
      },
      {
        title: "Third-Party Links on the FAID Platform",
        subContents: [
          {
            subContent:
              "The FAID Platform may contain links to third-party websites, software applications, " +
              "services and resources (collectively “Third-Party Services”) that are not under FAID’s control." +
              " We provide these links only as a convenience and are not responsible for the content, products " +
              "or services that are available from Third-Party Services. You acknowledge sole responsibility" +
              " and assume all risk arising from your use of any Third-Party Services.<br/><br/>" +
              "The FAID Platform may also provide the opportunity for you to link your FAID account, " +
              "FAID data, or the FAID Services with Third-Party Services. Although we offer this opportunity, " +
              "you acknowledge that any Third-Party Services that you use in connection with the FAID Platform " +
              "are not part of the FAID Platform. You acknowledge that these Terms of Use and the FAID Privacy " +
              "Policy do not apply to any Third-Party Services. You are responsible for reading and " +
              "understanding the terms and conditions and privacy policy that applies to your use of any " +
              "Third-Party Services.",
          },
        ],
      },
      {
        title: "Changes to the FAID Services and the FAID Platform",
        subContents: [
          {
            subContent:
              "FAID may change or discontinue, temporarily or permanently, any feature or component " +
              "of the FAID Platform at any time without notice. FAID is not liable to you or to any third party" +
              " for any modification, suspension or discontinuance of any feature or component of the FAID " +
              "Platform. We reserve the right to determine the timing and content of software updates, " +
              "which may be automatically downloaded and installed by Mobile Applications without prior " +
              "notice to you. Any data that is provided by an educational institution will not be retained " +
              "or displayed on the FAID Platform upon request from the educational institution to delete " +
              "such data. FAID will send an email to the appropriate personnel at such educational " +
              "institution certifying the deletion and destruction of such data.",
          },
        ],
      },
      {
        title: "Termination",
        subContents: [
          {
            subContent:
              "If you violate these Terms of Use, we reserve the right to terminate and/or deactivate " +
              "your FAID account and access to the FAID Platform, at our sole discretion, at any time and without " +
              "notice or liability to you. Upon any such termination, we may delete Your Content and other " +
              "information related to your account. You may cancel your account at any time by contacting " +
              "FAID’s customer support. Upon any termination, discontinuation or cancellation of the FAID " +
              "Platform or your account, the following provisions of these Terms of Use will survive:<br/><br/>" +
              "Posting Content on the FAID Platform<br/>" +
              "FAID’s Rights and Intellectual Property<br/>" +
              "Enforcement Rights<br/>" +
              "Termination<br/>" +
              "Disclaimers<br/>" +
              "Indemnity<br/>" +
              "Limitation of Liability<br/>" +
              "Dispute Resolution<br/>" +
              "General Terms<br/><br/>" +
              "Your Content will be destroyed upon the termination of your use of the FAID Platform. FAID will " +
              "send you an email (to your last known email address) certifying the destruction of Your Content " +
              "upon the termination of your use of the FAID Platform.",
          },
        ],
      },
      {
        title: "Disclaimers",
        subContents: [
          {
            subContent:
              "THE FAID PLATFORM AND FAID CONTENT ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. " +
              "WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY WARRANTIES OF MERCHANTABILITY, " +
              "FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR NON-INFRINGEMENT, AND ANY WARRANTIES " +
              "ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.<br/><br/>" +
              "We make no warranty that the FAID Platform or FAID Content will meet your requirements or be " +
              "available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the " +
              "quality, accuracy, timeliness, truthfulness, completeness or reliability of the FAID Platform " +
              "or any FAID Content. You acknowledge and agree that if you rely on any FAID Content or the FAID" +
              " Platform, you do so solely at your own risk.",
          },
        ],
      },
      {
        title: "Indemnity",
        subContents: [
          {
            subContent:
              "You will indemnify and hold harmless FAID and its officers, directors, employees and " +
              "agents, from and against any claims, disputes, demands, liabilities, damages, losses, and " +
              "costs and expenses, including, without limitation, reasonable attorneys’ fees arising out " +
              "of or in any way connected with: (i) your access to or use of the FAID Platform; (ii) Your " +
              "Content; or (iii) your breach of any warranties made by you hereunder or your violation " +
              "of any other provision of these Terms of Use. We reserve the right to assume control of " +
              "the defense of any third-party claim that is subject to indemnification by you, in which " +
              "event you will cooperate with us in asserting any available defenses.",
          },
        ],
      },
      {
        title: "Limitation of Liability",
        subContents: [
          {
            subContent:
              "NEITHER FAID, ITS PARTNERS, SUPPLIERS OR LICENSORS, NOR ANY OTHER PARTY INVOLVED IN " +
              "CREATING, PRODUCING, OR DELIVERING THE FAID PLATFORM OR THE FAID CONTENT WILL BE LIABLE FOR " +
              "ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF " +
              "DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF " +
              "SUBSTITUTE SERVICES ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF USE OR FROM THE USE " +
              "OF OR INABILITY TO USE THE FAID PLATFORM, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING " +
              "NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT FAID HAS BEEN " +
              "INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS " +
              "FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION " +
              "OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION " +
              "MAY NOT APPLY TO YOU. IN NO EVENT WILL FAID’S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION " +
              "WITH THESE TERMS OF USE OR FROM THE USE OF OR INABILITY TO USE THE FAID PLATFORM EXCEED THE " +
              "AMOUNTS YOU HAVE PAID TO FAID FOR USE OF THE FAID PLATFORM, OR ONE HUNDRED DOLLARS ($100) IF " +
              "YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO FAID, AS APPLICABLE.<br/><br/>" +
              "THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE " +
              "BASIS OF THE BARGAIN BETWEEN FAID AND YOU.",
          },
        ],
      },
      {
        title: "Dispute Resolution",
        subContents: [
          {
            subContent:
              "You agree that any dispute between you and FAID arising out of or relating to these " +
              "Terms of Use, the FAID Platform, the FAID Content or any other FAID products, devices or services " +
              "(collectively, “Disputes”) will be governed by the arbitration procedure outlined below.<br/><br/>" +
              "Governing Law: These Terms of Use and the resolution of any Disputes shall be governed by and " +
              "construed in accordance with the laws of the State of California without regard to its " +
              "conflict of laws principles.<br/><br/>" +
              "Informal Dispute Resolution: We want to address your concerns without the need of a formal " +
              "legal case. Before filing a claim against FAID, you agree to try to resolve the Dispute informally" +
              " by contacting ed@faid.co. We will try to resolve the Dispute informally by contacting" +
              " you. If a dispute is not resolved within 30 days after submission, you or FAID may bring a " +
              "formal proceeding.<br/><br/>" +
              "We Both Agree To Arbitrate: You and FAID agree to resolve any Disputes through final and binding" +
              " arbitration, except as set forth under Exceptions to Agreement to Arbitrate below.<br/><br/>" +
              "Opt-out of Agreement to Arbitrate: You can decline this agreement to arbitrate by contacting " +
              "ed@faid.co within 30 days of first accepting these Terms of Use and stating that you " +
              "(include your first and last name) decline this arbitration agreement.<br/><br/>" +
              "Arbitration Procedures: The American Arbitration Association (“AAA”) will administer the " +
              "arbitration under its Commercial Arbitration Rules and the Supplementary Procedures for Consumer " +
              "Related Disputes. The arbitration will be held in Los Angeles County, California, or any other " +
              "location we agree to in writing.<br/><br/>" +
              "Arbitration Fees: The AAA rules will govern payment of all arbitration fees. FAID will pay all " +
              "arbitration fees for claims less than $25,000. FAID will not seek its attorneys' fees and costs " +
              "in arbitration unless the arbitrator determines that your claim is frivolous.<br/><br/>" +
              "Exceptions to Agreement to Arbitrate: Either you or FAID may assert claims, if they qualify, in " +
              "small claims court in Los Angeles County, California. Either party may bring a lawsuit solely " +
              "for injunctive relief to stop unauthorized use or abuse of the FAID products, devices or the FAID " +
              "Platform, or infringement of intellectual property rights (for example, trademark, trade " +
              "secret, copyright or patent rights) without first engaging in arbitration or the informal " +
              "dispute-resolution process described above.<br/><br/>" +
              "No Class Actions: You may only resolve Disputes with FAID on an individual basis, and may not " +
              "bring a claim as a plaintiff or a class member in a class, consolidated, or representative " +
              "action. Class arbitrations, class actions, private attorney general actions, and consolidation " +
              "with other arbitrations are not allowed under our agreement.<br/><br/>" +
              "Judicial Forum for Disputes: In the event that the agreement to arbitrate is found not to apply " +
              "to you or your claim, you and FAID agree that any judicial proceeding will be brought in the " +
              "federal or state courts of Los Angeles County, California. Both you and FAID consent to venue " +
              "and personal jurisdiction there. We both agree to waive our right to a jury trial.<br/><br/>" +
              "Limitation on Claims: Regardless of any statute or law to the contrary, any claim or cause of " +
              "action arising out of or related to your use of the FAID Platform or products or the FAID " +
              "Content must be filed within one (1) year after such claim or cause of action arose, or " +
              "else that claim or cause of action will be barred forever.",
          },
        ],
      },
      {
        title: "General Terms",
        subContents: [
          {
            subContent:
              "Except as otherwise stated herein, these Terms of Use constitute the entire and " +
              "exclusive understanding and agreement between FAID and you regarding the FAID Platform, the FAID " +
              "Content, and these Terms of Use supersede and replace any and all prior oral or written " +
              "understandings or agreements between FAID and you regarding the FAID Platform and FAID Content. " +
              "If for any reason a court of competent jurisdiction finds any provision of these Terms of Use " +
              "invalid or unenforceable, that provision will be enforced to the maximum extent permissible and " +
              "the other provisions of these Terms of Use will remain in full force and effect.<br/><br/>" +
              "You may not assign or transfer these Terms of Use, by operation of law or otherwise, without " +
              "FAID’s prior written consent. Any attempt by you to assign or transfer these Terms of Use, " +
              "without such consent, will be null and void. FAID may freely assign or transfer these Terms of " +
              "Use without restriction. Subject to the foregoing, these Terms of Use will bind and inure to " +
              "the benefit of the parties, their successors and permitted assigns.<br/><br/>" +
              "Any notices or other communications provided by FAID under these Terms of Use, including those " +
              "regarding modifications to these Terms of Use, will be given: (i) via email; or (ii) by posting " +
              "to the FAID Platform at www.faid.co. For notices made by e-mail, the date of receipt on " +
              "the message will be deemed the date on which such notice is transmitted.<br/><br/>" +
              "FAID’s failure to enforce any right or provision of these Terms of Use will not be considered " +
              "a waiver of such right or provision. The waiver of any such right or provision will be effective " +
              "only if in writing and signed by a duly authorized representative of FAID. Except as expressly " +
              "set forth in these Terms of Use, the exercise by either party of any of its remedies under these " +
              "Terms of Use will be without prejudice to its other remedies under these Terms of Use or otherwise.",
          },
        ],
      },
      {
        title: "Additional Terms May Apply",
        subContents: [
          {
            subContent:
              "Additional terms may apply to certain FAID products or services. In the event that " +
              "there is a conflict between these Terms of Use and any additional terms, the additional terms will control.",
          },
        ],
      },
      {
        title: "Contact Us",
        subContents: [
          {
            subContent:
              "<b>Please contact us at if you have any questions, complaint or comments regarding " +
              "these Terms of Use:</b><br/><br/>" +
              "FAID, Inc.<br/>" +
              "<a>ed@faid.co</a><br/>" +
              "3410 West Neap Place<br/>" +
              "Oxnard, Ca 93035",
          },
        ],
      },
    ],
    footer,
  },
  privacy: {
    header,
    policyHeader: {
      type: "policy",
      url: "#",
      lastUpdatedAt: "August 27, 2018",
      content:
        "Your information privacy is extremely important to FAID, Inc. We have prepared this Privacy " +
        "Policy to explain how we collect, use, protect, and disclose the Personal Information (as defined below) you " +
        "share with us when you use www.faid.co and any of our mobile applications and other FAID services " +
        '(collectively, the "<b>FAID Products and Services</b>"). This Privacy Policy also explains your choices for managing ' +
        "your information preferences, including opting out of certain uses of your Personal Information. This Privacy " +
        "Policy applies to all users of the Site and FAID Products and Services.",
      footer:
        "BY USING THE SITE AND/OR THE FAID PRODUCTS AND SERVICES, YOU ARE CONSENTING TO THIS PRIVACY POLICY. " +
        "PLEASE READ THE ENTIRE PRIVACY POLICY AND ALSO OUR TERMS OF SERVICE CAREFULLY.",
    },
    policyItems: [
      {
        title: "Managing Your Information Preferences",
        subContents: [
          {
            subContent:
              "You can review, correct, update, or change your Personal Information or opt out of receiving certain " +
              "e-mails by changing the relevant settings in your account on our Site or by e-mailing us at " +
              "ed@faid.co. You can erase all Personal Information from the FAID Products and Services by deleting " +
              "all of the stored information. You can also email us at ed@faid.co and request for the deletion of " +
              "your Personal Information and/or the transfer of such information to a personal account from the FAID Products " +
              "and Services. Otherwise, FAID’s policy is to delete a user’s Personal Information six (6) months after the end " +
              "of the school year of the user’s senior year of high school. You are able to opt out of receiving marketing " +
              "e-mails from us; however, you cannot opt out of receiving e-mails related to the status or maintenance of your " +
              "account. If you have any questions, complaint or comments regarding this Privacy Policy, you can contact us at: " +
              "<br/><br/>FAID, Inc.<br/><a href='#'>ed@faid.co</a><br/>1431 Ocean Avenue #1202<br/> " +
              "Santa Monica, Ca 90401",
          },
        ],
      },
      {
        title: "Information Collection",
        subContents: [
          {
            subContent:
              "FAID collects no more information than necessary to deliver its products and services " +
              "through its Site and the FAID Products and Services. FAID does not use personally identifiable " +
              "information in student records from educational institutions for targeted advertising.",
          },
          {
            subTitle: "Creating an Account - Personal Information",
            subContent:
              "We collect information that personally identifies you, such as your name, address, " +
              "phone number, mobile phone number, e-mail address, payment and financial information, gender, " +
              "age and other personally identifiable information that you choose to provide us with or that " +
              "you choose to include in your account information (“<b>Personal Information</b>”). You will be asked " +
              "to provide us with Personal Information when you register with the Site and create an account, " +
              "or use any of the FAID Products and Services, and at other times. We may also receive Personal " +
              "Information, including your username, social network ID, and posts that you have shared, from " +
              "social media websites and services (e.g., Facebook, Twitter, Instagram, Pinterest, LinkedIn " +
              "and Google+) if you choose to enable those features. We use this information to help us " +
              "understand how people use the Site and the FAID Products and Services, and to enhance the " +
              "products and services we offer.",
          },
          {
            subTitle: "Usage Data and Site Activity",
            subContent:
              "We automatically collect information regarding your usage of and activity on the Site " +
              "(“<b>Usage Data</b>”). For example, each time you use the Site we automatically collect the type of " +
              "Web browser you use, your operating system, your Internet service provider, your IP address, " +
              "the pages you view, and the time and duration of your visits to the Site. We use this " +
              "information to help us understand how people use the Site and the FAID Products and Services, " +
              "and to enhance the products and services we offer.",
          },
          {
            subTitle: "Surveys and Sweepstakes or Contests",
            subContent:
              "From time to time, we may administer sweepstakes, contests or surveys. Personal " +
              "Information is obtained from you when you respond to web surveys, sweepstakes or contests. " +
              "We may use this Personal Information to provide you with information and services for which " +
              "you have expressed an interest or that you may find useful based on your answers in a survey, " +
              "sweepstake or contest. We might also send out e-mails to users and sweepstakes or contest " +
              "entrants to announce special offers, services and announcements from our Site and/or third " +
              "party advertisers. Additionally, we may refer to your Personal Information to better understand " +
              "your needs and how we can improve our Site and the FAID Products and Services.",
          },
          {
            subTitle: "Newsletters",
            subContent:
              "Personal Information is obtained from you when you request to subscribe to a" +
              "newsletter. We may use this personally identifiable information to deliver the newsletters" +
              "that you have elected to receive. We might also send out e-mails to announce special offers," +
              "services and announcements from our Site or other information responsive to the data you" +
              "provide us. Additionally, we may refer to your Personal Information to better understand" +
              "your needs and how we can improve our Site and the FAID Products and Services.",
          },
          {
            subTitle: "Community Posts",
            subContent:
              "To post to a FAID community message boards, you will be asked to create a community " +
              "username that is separate from your FAID account name. This community username will be posted " +
              "next to any comments you publish on any community message boards, which can be seen by all " +
              "other users of our Site and the FAID Products and Services. Other information, like a profile " +
              "photo that you have added to your FAID account may also be visible on message boards, depending " +
              "on your account settings.",
          },
          {
            subTitle: "Cookies and Anonymous Identifiers",
            subContent:
              "We do not use cookies or other anonymous identifiers. However, some of our Service " +
              "Providers (defined below) may use their own cookies, anonymous identifiers, or other tracking " +
              "technology in connection with the services they perform on our behalf.",
          },
        ],
      },
      {
        title: "Educational Institutions",
        subContents: [
          {
            subContent:
              "Educational institutions have the right to review, have deleted and/or refuse to " +
              "permit further collection of information of a user (student). A deletion or refusal to permit " +
              "such further collection of information may affect the accuracy of the FAID Products and Services. " +
              "FAID will allow for inspection, review and amendment or changes to user (student) information by " +
              "an authorized request from an educational institution. Educational institutions can contact us at:" +
              "<br/><br/>FAID, Inc.<br/><a href='#'>ed@faid.co</a>",
          },
        ],
      },
      {
        title: "How we Use, Disclose and Share Information",
        subContents: [
          {
            subContent:
              "We use Personal Information for internal purposes only, such as to provide you with " +
              "the Site and the FAID Products and Services, to better understand and analyze our customer " +
              "demographic, to send you alerts about your account, to send marketing communications to you, " +
              "to improve the Site and the FAID Products and Services, to notify you of new products or " +
              "services, and to otherwise communicate with you. We may also use your Personal Information to " +
              "provide more relevant search results from the search functions of the Site and the FAID " +
              "Products and Services. We will not disclose Personal Information to third parties, except " +
              "as explained in this Privacy Policy. We may disclose to third parties, certain Personal " +
              "Information or Usage Data regarding the Site and the FAID Products and Services. However, in " +
              "such cases, your Personal Information and Usage Data is aggregated with the Personal " +
              "Information and Usage Data of others and do not identify you individually. If your user " +
              "account has been assigned to you by a participating educational institution, certain faculty " +
              "users from your respective educational institutional may be provided secure access to some of " +
              "your Personal Information in order to assist with your utilization of the FAID Products and Services.",
          },
        ],
      },
      {
        title: "Personal Information You Make Available to Others",
        subContents: [
          {
            subContent:
              "By using the Site or the FAID Products and Services you may make certain of your " +
              "Personal Information available to others. For example, when you post a comment on the Site, " +
              "or want to connect with another user through the Site or the FAID Products and Services, other " +
              "users may see your username, certain Personal Information, and other information that you " +
              "choose to make available to others.",
          },
        ],
      },
      {
        title: "Data That Does Not Identify You (De-identified Data)",
        subContents: [
          {
            subContent:
              "FAID may share or sell aggregated, de-identified data that does not identify you, " +
              "with partners and the public in a variety of ways, such as by providing research or reports. " +
              "When we provide this information, we perform appropriate procedures so that the data does not " +
              "identify you and we contractually prohibit recipients of the data from re-identifying it back to you.",
          },
        ],
      },
      {
        title: "Service Providers",
        subContents: [
          {
            subContent:
              "From time to time, we may establish a business relationship with other businesses " +
              "whom we believe are trustworthy and who have confirmed that their privacy practices are " +
              "consistent with ours (“<b>Service Providers</b>”). For example, we may contract with Service " +
              "Providers to provide certain services, such as hosting and maintenance, data storage and " +
              "management, payment processing, and marketing and promotions. We only provide our Service " +
              "Providers with the information necessary for them to perform these services on our behalf. " +
              "Each Service Provider must agree to use commercially reasonable security procedures and " +
              "practices, appropriate to the nature of the information involved, in order to protect your " +
              "Personal Information from unauthorized access, use, or disclosure. Service Providers are " +
              "prohibited from using Personal Information other than as specified by us.",
          },
        ],
      },
      {
        title: "Other Transfers of Personal Information and Usage Data",
        subContents: [
          {
            subContent:
              "We may share Personal Information and Usage Data with businesses controlling, " +
              "controlled by, or under common control with FAID. If FAID is merged, acquired, or sold, or in " +
              "the event of a transfer of some or all of our assets (including by bankruptcy procedures), " +
              "we may disclose or transfer Personal Information and Usage Data in connection with such " +
              "transaction. You will have the opportunity to opt out of any such transfer if, in our sole " +
              "discretion (or the discretion of a trustee), it will result in the handling of your Personal " +
              "Information and Usage Data in a way that is materially different from this Privacy Policy.",
          },
        ],
      },
      {
        title: "Compliance with Laws and Law Enforcement",
        subContents: [
          {
            subContent:
              "We cooperate with government, law enforcement officials and private parties to enforce " +
              "and comply with the law. We may disclose Personal Information, Usage Data and any other " +
              "information about you to the government, law enforcement officials or private parties if, " +
              "in our sole discretion, we believe it is necessary or appropriate in order to respond to legal " +
              "requests (including court orders and subpoenas), to protect the safety, property, or rights of " +
              "FAID or of any third party, to prevent or stop any illegal, unethical, or legally actionable " +
              "activity, or to comply with the law.",
          },
        ],
      },
      {
        title: "Be Careful When You Share Information with Others",
        subContents: [
          {
            subContent:
              "Please be aware that whenever you share information on any public area of the Site or " +
              "the FAID Products and Services, that information may be accessed by others. In addition, please " +
              "remember that when you share information in any other communications with third parties, that " +
              "information may be passed along or made public by others. This means that anyone with access " +
              "to such information can potentially use it for any purpose, including sending unsolicited communications.",
          },
        ],
      },
      {
        title: "Security and Data Breach",
        subContents: [
          {
            subContent:
              "We maintain commercially reasonable physical, electronic, and procedural safeguards to " +
              "protect the confidentiality and security of information transmitted to us, including Personal " +
              "Information and Usage Data. However, no data transmission over the Internet or other network " +
              "can be guaranteed to be 100% secure. In the unlikely event that FAID must provide you a notice " +
              "of a security breach, FAID will send you security breach notices to the e-mail address contained " +
              "in your account information unless we are otherwise require by law. Please note: many e-mail " +
              "systems have built in SPAM filters. If you have one in place, you should check with your system " +
              "administrator or the available instructions to confirm that e-mails from FAID are not blocked by " +
              "the filter (e.g., by confirming that the Service domain name (faid.co) is a permitted domain name.",
          },
        ],
      },
      {
        title: "Links to Third Party Web Sites",
        subContents: [
          {
            subContent:
              "Our Site and the FAID Products and Services may contain links to other websites or allow " +
              "others to send you such links. A link to a third party’s website does not mean that we endorse " +
              "it or that we are affiliated with it. We do not exercise control over third-party websites. " +
              "You access such third-party websites or content at your own risk. You should always read the " +
              "privacy policy of a third-party website before providing any information to the website.",
          },
        ],
      },
      {
        title: "Minors",
        notice:
          "PLEASE NOTE THAT: Our Web Sites and our Products and Services are not suitable for persons under the age of 14.",
        subContents: [
          {
            subContent:
              "We do not knowingly collect Personal Information from children under the age of 14. " +
              "If we become aware that we have inadvertently received Personal Information from a child " +
              "under the age of 14, we will delete such information from our records. California users under " +
              "the age of 18 may request the removal of their content or information publicly posted on the " +
              "Site or the FAID Products and Services by e-mailing us at ed@faid.co. Please note that " +
              "most of the user content that appears on our Site or the FAID Products and Services is stored " +
              "and controlled by third party providers; thus, complete and comprehensive removal of the " +
              "content may not be possible.",
          },
        ],
      },
      {
        title: "FERPA and California AB 1584.",
        subContents: [
          {
            subContent:
              "Regarding FERPA and California AB 1584 (Buchanan) Privacy of Pupil Records: 3rd-Party " +
              "Digital Storage & Education Software (Education Code section 49073.1), FAID will abide to the " +
              "following:<br/><br/>1. Any student records received by FAID from an educational institution " +
              "continue to be the property of and under the control of the educational institution. The " +
              "educational institution retains full ownership rights to the personal information and " +
              "education records it provides to FAID.<br/><br/>2. FAID users may retain possession and control " +
              "of their own generated content by submitting a request to FAID.<br/><br/>3. FAID will not use " +
              "any information in a student record for any purpose other than those required or specifically " +
              "permitted by the FAID Terms of Use and Privacy Policy.<br/><br/>4. Parents, legal guardians, or " +
              "eligible students may review personally identifiable information in the student’s records and " +
              "correct erroneous information by contacting their educational institution. Additionally, FAID " +
              "users may access, correct, update, or delete personal information in their profile by signing " +
              "into FAID, accessing their FAID account, and making the appropriate changes.<br/><br/>5. FAID is " +
              "committed to maintaining the security and confidentiality of student records. Towards this end, " +
              "we take the following actions: (a) we limit employee access to student data to only those " +
              "employees with a need to such access to fulfill their job responsibilities; (b) we conduct " +
              "background checks on our employees that may have access to student data; (c) we conduct regular " +
              "employee privacy and data security training and education; and (e) we protect personal " +
              "information with technical, contractual, administrative, and physical security safeguards " +
              "in order to protect against unauthorized access, release or use.<br/><br/>6. In the event of " +
              "an unauthorized disclosure of a student’s records, FAID will (1) promptly notify the respective " +
              "users unless specifically directed not to provide such notification by law enforcement officials. " +
              "Notification shall identify: (i) the date and nature of the unauthorized use or disclosure; " +
              "(ii) the private data used or disclosed; (iii) a general description of what occurred including " +
              "who made the unauthorized use or received the unauthorized disclosure; (iv) what FAID has done " +
              "or shall do to mitigate any effect of the unauthorized use or disclosure; (v) what corrective " +
              "action FAID has taken or shall take to prevent future similar unauthorized use or disclosure; " +
              "and (vi) who at FAID the user can contact. FAID will keep the user fully informed until the " +
              "incident is resolved.<br/><br/>7. FAID will delete or de-identify personal information when it " +
              "is no longer needed upon expiration or termination of our agreement with an educational " +
              "institution with any deletion or de-identification to be completed according to the terms of " +
              "our agreement with the educational institution, or at the direction or request of the " +
              "educational institution.<br/><br/>8. FAID agrees to work with educational institutions to " +
              "ensure compliance with FERPA and the parties will ensure compliance by providing parents, " +
              "legal guardians or eligible students with the ability to inspect and review student records " +
              "and to correct any inaccuracies therein as described in statement (4) above.<br/><br/>9. FAID " +
              "prohibits using personally identifiable information in student records to engage in targeted " +
              "advertising.<br/><br/>10. FAID will not make material changes to our Terms of Use or Privacy " +
              "Policy, including making significant changes impacting the collection, use, disclosure or " +
              "retention of data collected without thirty (30) days prior notice to the educational user." +
              "<br/><br/>ALL AUTHORIZED USERS ARE SOLELY RESPONSIBLE FOR PROVIDING OR OBTAINING ALL NECESSARY " +
              "AUTHORIZATIONS AND CONSENTS FROM PARENTS/GUARDIANS REQUIRED UNDER APPLICABLE PRIVACY LAWS FOR " +
              "THE COLLECTION OF PERSONAL DATA PRIOR TO SHARING OR DISCLOSING SUCH INFORMATION TO FAID.",
          },
        ],
      },
      {
        title: "Processing in the United States",
        subContents: [
          {
            subContent:
              "Please be aware that your Personal Information and communications may be transferred " +
              "to and maintained on servers or databases located outside your state, province, or country. " +
              "By using the Site or the FAID Products and Services, you agree that the collection, use, " +
              "transfer, and disclosure of your Personal Information, Usage Data and communications will " +
              "be governed by the applicable laws in the United States.",
          },
        ],
      },
      {
        title: "Privacy Policy Changes",
        subContents: [
          {
            subContent:
              "We may change this Privacy Policy from time to time in our sole discretion. If we " +
              "decide to change this Privacy Policy, we will inform you by posting the revised Privacy Policy " +
              "on the Site. Those changes will go into effect on the “Revised Date” shown in the revised " +
              "Privacy Policy. By continuing to use the Site or the FAID Products and Services, you are " +
              "consenting to the revised Privacy Policy. Educational institutions will be notified by email " +
              "at least 30 days before the implementation of any material changes to this Privacy Policy, " +
              "including practices around new or additional data collection, or practices that may lessen " +
              "the previously noted protections around student data privacy.",
          },
        ],
      },
      {
        title: "Disclaimer",
        subContents: [
          {
            subContent:
              "PLEASE PRINT A COPY OF THIS PRIVACY POLICY FOR YOUR RECORDS AND PLEASE CHECK THE SITE " +
              "FREQUENTLY FOR ANY CHANGES.",
          },
        ],
      },
    ],
    footer,
  },
  calculateData,
  header,
  footer,
  dashboard: {
    apiCalls: [
      {
        component: "Organisms > DashboardTabs > Result",
        name: "query users",
        description:
          "use this query to get the logged in user data to access schools",
        params: {
          id: "${LOGGED_IN_USER_ID}",
        },
        fields: {
          users: {
            inStateSchools: {
              GooglePlayImg: "school.medias[0]['source']",
              "Standford University": "school.name",
              "Upront Cost/yr": "school.costOfAttendance",
            },
            outStateSchools: {
              GooglePlayImg: "school.medias[0]['source']",
              "Standford University": "school.name",
              "Upront Cost/yr": "school.costOfAttendanceOutState",
            },
          },
        },
      },
      {
        component: "Organisms > DashboardTabs > Compare",
        name: "query schools",
        description:
          "use this query to get the list of schools for compare dropdown",
        params: {
          isActive: true,
        },
        fields: {
          schools: {
            label: "school.name",
          },
        },
      },
      {
        component: "Organisms > DashboardTabs > Compare",
        name: "query users",
        description:
          "User.schoolList is used for the compare list. All the schools in this list should show in the compare section. If a user adds a school from the dropdown, it should add that school to User.schoolList",
        params: {
          id: "${LOGGED_IN_USER_ID}",
        },
        fields: {
          users: {
            schoolList: {},
          },
        },
      },
      {
        component: "Organisms > DashboardTabs > Compare > Application Summary",
        name: "query users",
        description:
          "User.schoolList is used for the compare list. All the schools in this list should show in the compare section. If a user adds a school from the dropdown, it should add that school to User.schoolList",
        params: {
          id: "${LOGGED_IN_USER_ID}",
        },
        fields: {
          users: {
            schoolList: {
              "University of California Schools": "",
              "Cal State Schools": "",
              "All Other Schools": "",
            },
          },
        },
      },
    ],
    themeColor: "primary",
    backBtnLabel: "Back to Application",
    tabs: [
      { id: 0, name: "+Colleges", href: "#" },
      { id: 1, name: "Compare", href: "#" },
      { id: 2, name: "FAFSA + CSS", href: "#" },
      { id: 3, name: "Courses", href: "#" },
      { id: 4, name: "Resources", href: "#" },
      { id: 5, name: "Application", href: "#" },
    ],
    selectedTabId: 0,

    resultProps: {
      GreetingProps: {
        title: "Test, Welcome to your Dashboard",
        avatar: {
          url: require("../assets/public/images/profile.png"),
          size: "32",
        },
        content: {
          header:
            "<b>Good news!</b> Based on the details you’ve provided, you qualify for free money from the " +
            "colleges below. Upgrade your account to see how much Free Money they’re offering you.",
          paidHeader:
            "<b>Good news!</b> Based on the details you’ve provided, you qualify for free money from the " +
            "colleges below.",
          footer:
            '<div class="mt-4">Your Dashboard is the control panel to </div><ul class="dashboard-list"><li class="mt-2 text-primary font-bold">Build your College List</li><li class="mt-2 text-primary font-bold">Compare your Colleges Financial Costs</li><li class="mt-2 text-primary font-bold">Prepare you for the FAFSA & CSS Profile</li></ul>',
        },
        button: {
          label: "Upgrade Now",
          show: true,
        },
      },
      calendarProps: {
        label: "SAT/ACT CALENDAR",
        items: [
          {
            date: "Oct 3",
            label: "March SAT Late Registration Role",
          },
          {
            date: "Oct 5",
            label: "April ACT Late Registration Date",
          },
        ],
        menuItems: [
          {
            id: 0,
            label: "View completed",
          },
          {
            id: 1,
            label: "Choose school year",
          },
        ],
        buttons: [
          {
            id: 0,
            label: "Freshman",
          },
          {
            id: 1,
            label: "Sophomore",
          },
          {
            id: 2,
            label: "Junior",
          },
          {
            id: 3,
            label: "Senior",
          },
        ],
      },
      startApplicationProps: {
        label: "Start Application",
        href: "/#/application",
      },
      dashboardButtonsProps: {
        barFilters: [
          { name: "2.0 to 2.9", href: "#", current: true },
          { name: "3.0 to 3.4", href: "#", current: false },
          { name: "3.5 to 4.0+", href: "#", current: false },
          { name: "No Loan Schools", href: "#", current: false },
        ],
        buttons: [
          {
            iconName: "",
            title: "Vue functional components",
            content: "A set of free MIT-licensed high-quality SVG icons",
            color: "yellow-400",
          },
          // {
          //   iconName: 'ViewListIcon',
          //   title: 'My List',
          //   content: '',
          //   color: 'yellow-400'
          // },
          // {
          //   iconName: 'FilterIcon',
          //   title: 'Filter',
          //   content: '',
          //   color: 'primary'
          // },
        ],
        filterBox: {
          selectProps: {
            items: [
              { id: 0, name: "Sort by Upfront Cost (Lowest First)" },
              { id: 1, name: "Sort by Grants (Highest First)" },
            ],
            selectedItem: {
              id: 0,
              name: "Sort by Upfront Cost (Lowest First)",
            },
          },
          privateLabel: "Private Colleges",
          publicLabel: "Public Colleges",
        },
        modalProps: {
          title: "My College List",
          schools: [
            {
              type: "IN-STATE COLLEGES",
              upfrontLabel: "UPFRONT COST/YR",
              emptyLabel: "No colleges in this list",
              list: [
                {
                  name: "Cal State University Channel Islands",
                  amount: 0,
                  isLocked: true,
                },
                {
                  name: "Stanford University",
                  amount: 0,
                  isLocked: false,
                },
              ],
            },
            {
              type: "OUT-OF-STATE COLLEGES",
              upfrontLabel: "UPFRONT COST/YR",
              emptyLabel: "No colleges in this list",
              list: [],
            },
          ],
          buttonLabel: "Compare & Apply",
        },
      },
      inSchools: {
        title: "In-State Schools",
        list: [
          {
            logo: {
              url: require("../assets/public/images/google-play-badge.png"),
              width: "48",
              height: "auto",
            },
            name: "Stanford University",
            title: "Upront Cost/yr:",
            amount: "$ 0",
            status: true,
          },
          {
            logo: {
              url: require("../assets/public/images/google-play-badge.png"),
              width: "48",
              height: "auto",
            },
            name: "Stanford University",
            title: "Upront Cost/yr:",
            amount: "$ 0",
            status: true,
          },
          {
            logo: {
              url: require("../assets/public/images/google-play-badge.png"),
              width: "48",
              height: "auto",
            },
            name: "Stanford University",
            title: "Upront Cost/yr:",
            amount: "$ 0",
            status: true,
          },
          {
            logo: {
              url: require("../assets/public/images/google-play-badge.png"),
              width: "48",
              height: "auto",
            },
            name: "Stanford University",
            title: "Upront Cost/yr:",
            amount: "$ 0",
            status: true,
          },
        ],
      },
      outSchools: {
        title: "Out-of-State Schools",
        list: [
          {
            logo: {
              url: require("../assets/public/images/google-play-badge.png"),
              width: "48",
              height: "auto",
            },
            name: "Stanford University",
            title: "Upront Cost/yr:",
            amount: "$ 0",
            status: true,
          },
          {
            logo: {
              url: require("../assets/public/images/google-play-badge.png"),
              width: "48",
              height: "auto",
            },
            name: "Stanford University",
            title: "Upront Cost/yr:",
            amount: "$ 0",
            status: true,
          },
          {
            logo: {
              url: require("../assets/public/images/google-play-badge.png"),
              width: "48",
              height: "auto",
            },
            name: "Stanford University",
            title: "Upront Cost/yr:",
            amount: "$ 0",
            status: true,
          },
          {
            logo: {
              url: require("../assets/public/images/google-play-badge.png"),
              width: "48",
              height: "auto",
            },
            name: "Stanford University",
            title: "Upront Cost/yr:",
            amount: "$ 0",
            status: true,
          },
        ],
      },
      moreSchoolLabel: "Show More Schools",
    },

    compareProps: {
      title: "Compare Schools",
      desc:
        "We recommend that your final college list be about 7-10 schools in the same geographic area.<br/>" +
        "This will strengthen your chances of getting accepted.",
      selectOptions: [
        "Cal State University Channel Islands",
        "Stanford University",
        "University of California, Los Angeles",
        "University of Redlands",
        "San Diego State University",
        "University of California, Riverside",
        "California State University",
        "Fullerton",
        "San Jose State University",
      ],
      compareLabel: "Compare",
      compareProps: {
        school: {
          image: require("../assets/public/images/stanford.png"),
          removeButtonLabel: "Remove From College List",
          name: "Cal State University Channel Islands",
          descItems: [
            {
              title: "",
              items: [
                {
                  isLocked: false,
                  label: "Grants/yr",
                },
                {
                  isLocked: false,
                  label: "Grants/yr<br/><b>$0</b>",
                },
              ],
            },
            {
              title: "Living On Campus",
              icon: "HomeIcon",
              items: [
                {
                  isLocked: false,
                  label: "Original Cost",
                },
                {
                  isLocked: false,
                  label: "Grants<br/>$71,587",
                },
                {
                  isLocked: false,
                  label: "Loans",
                },
                {
                  isLocked: false,
                  label: "Upfront Cost/yr<br/><b>$0</b>",
                },
              ],
            },
            {
              title: "Living at home",
              icon: "HomeIcon",
              items: [
                {
                  isLocked: false,
                  label: "Original Cost",
                },
                {
                  isLocked: false,
                  label: "Grants<br/>$55,824 - $NaN",
                },
                {
                  isLocked: false,
                  label: "Loans",
                },
                {
                  isLocked: false,
                  label: "Upfront Cost/yr<br/><b>$NaN - $0</b>",
                },
              ],
            },
          ],
        },
      },
      summary: {
        title: "Application Summary",
        cards: [
          {
            title: "UNIVERSITY OF CALIFORNIA SCHOOLS",
            list: [],
          },
          {
            title: "CAL STATE SCHOOLS",
            list: [
              {
                name: "Cal State University Channel Islands",
                isLocked: true,
              },
            ],
          },
          {
            title: "ALL OTHER SCHOOLS",
            list: [
              {
                name: "Stanford University",
                isLocked: false,
                amount: 0,
              },
            ],
          },
        ],
        emptyLabel: "No school selected for this category",
      },
      applyColleges: [
        {
          img: require("../assets/public/images/uc-logo.png"),
          label: "Apply To UC Colleges",
          isAble: false,
          link: "https://apply.universityofcalifornia.edu/my-application/login",
        },
        {
          img: require("../assets/public/images/csu-logo.png"),
          label: "Apply To Cal State Colleges",
          isAble: true,
          link: "https://www2.calstate.edu/apply",
        },
        {
          img: require("../assets/public/images/com-logo.png"),
          label: "Apply To Common App Colleges",
          isAble: true,
          link: "https://apply.commonapp.org/Login",
        },
      ],
    },

    fafsaCssProps: {
      subTabs: [
        { name: "FAFSA", href: "#", current: true },
        { name: "CSS Profile", href: "#", current: false },
      ],
      fafsaProps: {
        fafsaCheckList: [
          "FSA ID's for student & both parents",
          "Student's driver's license (if possible)",
          "Date of birth for student & both parents.",
          "Social Security Number or Permanent Resident Number for student & both parents.",
          "Parent's marital status/date",
          "W2 forms for student & both parents.",
          "2022 tax returns for student & both parents.",
          "Parent's income & asset information (real estate, stocks, social security, other investments, etc.)",
        ],
        descriptions: [
          {
            title: "Ready to start?",
            subContents: [
              {
                subContent:
                  "When you are ready to begin your FAFSA, click below.",
              },
            ],
          },
        ],
        buttonLabel: "Begin FAFSA",
        greetingProps: {
          title: "FAFSA",
          avatar: {
            url: require("../assets/public/images/profile.png"),
            size: "32",
          },
          content: {
            header:
              "To apply for financial aid you have to fill out the FAFSA. Don’t worry, it's easier than it " +
              "sounds.<br/><br/>The FAFSA is a form that the US government uses to determine your eligibility for " +
              "financial aid. Nearly every student is eligible for some form of financial aid, so everyone should " +
              "fill out the FAFSA.<br/><br/>You can begin your FAFSA through FAID at any time, and you can " +
              "submit your application as early as October 1st. California's deadline to submit the FAFSA " +
              "is March 2nd, but you should submit your application as soon as possible to receive your " +
              "maximum financial aid.<br/><br/>The first step is to <b class='text-orange'>Create an FSA ID</b>. ​You can create one at " +
              "<a href='https://studentaid.gov/fsa-id/create-account/launch' target='_blank'>https://studentaid.gov/fsa-id/create-account/launch</a><br/>" +
              "<ul>" +
              "<li><b>Already have an FSA ID?​</b> <a href='https://studentaid.gov/fsa-id/sign-in/landing' target='_blank'>Continue the FAFSA here</a></li>" +
              "<li><b>Forgot your username and password?​</b><br/>Forgot your username and password?​<a href='https://studentaid.gov/help-center/answers/article/help-accessing-account-fsa-id' target='_blank'>You can retrieve them here</a></li>" +
              "</ul>",
          },
        },
        calendarProps: {
          label: "SAT/ACT CALENDAR",
          items: [
            {
              date: "Oct 3",
              label: "March SAT Late Registration Role",
            },
            {
              date: "Oct 5",
              label: "April ACT Late Registration Date",
            },
          ],
          menuItems: [
            {
              id: 0,
              label: "View completed",
            },
            {
              id: 1,
              label: "Choose school year",
            },
          ],
          buttons: [
            {
              id: 0,
              label: "Freshman",
            },
            {
              id: 1,
              label: "Sophomore",
            },
            {
              id: 2,
              label: "Junior",
            },
            {
              id: 3,
              label: "Senior",
            },
          ],
        },
      },
      CssProps: {
        greetingProps: {
          title: "CSS Profile",
          avatar: {
            url: require("../assets/public/images/profile.png"),
            size: "32",
          },
          content: {
            header:
              "While the <span class='text-primary'>FAFSA</span> helps you qualify for public government aid, the CSS Profile " +
              "is an online application for <b>private, non-federal financial aid.</b><br/><br/>We can’t submit " +
              "the CSS Profile for you, but we can still help out. The first step is to determine if you need " +
              "to complete it.",
          },
        },
        calendarProps: {
          label: "SAT/ACT CALENDAR",
          items: [
            {
              date: "Oct 3",
              label: "March SAT Late Registration Role",
            },
            {
              date: "Oct 5",
              label: "April ACT Late Registration Date",
            },
          ],
          menuItems: [
            {
              id: 0,
              label: "View completed",
            },
            {
              id: 1,
              label: "Choose school year",
            },
          ],
          buttons: [
            {
              id: 0,
              label: "Freshman",
            },
            {
              id: 1,
              label: "Sophomore",
            },
            {
              id: 2,
              label: "Junior",
            },
            {
              id: 3,
              label: "Senior",
            },
          ],
        },
        subContent: {
          title: "Do You Need to Complete the CSS Profile?",
          desc:
            "The CSS Profile is used by about 400 colleges across the United States. It enables these colleges " +
            "to get a more thorough picture of your financial needs before determining how much aid to " +
            "give you.<br/><br/>Not every college requires it. But many do.<br/><br/>" +
            "Check out the <a href='https://profile.collegeboard.org/profile/ppi/participatingInstitutions.aspx' target='_blank'>list of colleges that require the CSS Profile application</a>. If even " +
            "one of the colleges you want to apply to is on this list, you should complete it.",
        },
        descriptions: [
          {
            title: "Completing your CSS Profile",
            subContents: [
              {
                subContent:
                  "The deadline to complete the CSS Profile is two weeks before the earliest " +
                  "priority filing date specified by your colleges. The CSS profile opens <b>October 1st</b>" +
                  "​ and you should complete it as soon as possible to avoid missing any deadlines.<br/><br/>" +
                  "Follow these steps to complete your CSS Profile:<br/><br/>" +
                  "<span class='list-num'>1</span><a href='https://www.collegeboard.org/' target='_blank'>Create a College Board account</a>. If you already " +
                  "have an account, <a href='https://www.collegeboard.org/' target='_blank'>sign in here</a>.<br/><br/>" +
                  "<span class='list-num'>2</span>Make sure you have the following:" +
                  "<ul><li>Driver’s license</li>" +
                  "<li>Social security numbers</li>" +
                  "<li>Most recently completed tax returns</li>" +
                  "<li>W-2 forms and other records of current year income</li>" +
                  "<li>Records of untaxed income and benefits for the current and previous tax years</li>" +
                  "<li>Current bank statements</li>" +
                  "<li>Records of savings, stocks, bonds, trusts etc</li></ul><br/>" +
                  "<span class='list-num'>3</span>Complete the full application.<br/><br/>" +
                  "<span class='list-num'>4</span>Pay fees in order to submit. The initial application " +
                  "fee is $25, and gives you one college report. Each additional report is $16. " +
                  "The CSS profile is free for undergraduate students whose family income is less than $100,000",
              },
            ],
          },
          {
            title: "Need assistance?",
            subContents: [
              {
                subContent:
                  "The <a href='https://www.collegeboard.org/' target='_blank'>College Board website</a> has some helpful guides & tutorials.",
              },
            ],
          },
          {
            title: "Ready to start?",
            subContents: [
              {
                subContent:
                  "When you are ready to begin your CSS Profile application, click below.",
              },
            ],
          },
        ],
        buttonLabel: "Begin CSS Profile",
      },
    },

    courseProps: {
      categories: [
        { name: "Category 1" },
        { name: "Category 2" },
        { name: "Category 3" },
        { name: "Category 4" },
      ],
      videos: [
        { title: "Video 1", image: "" },
        { title: "Video 2", image: "" },
        { title: "Video 3", image: "" },
        { title: "Video 4", image: "" },
        { title: "Video 5", image: "" },
        { title: "Video 6", image: "" },
        { title: "Video 7", image: "" },
        { title: "Video 8", image: "" },
      ],
      selectedVideo: {},
    },

    resourceProps: {
      GreetingProps: {
        title: "Resources",
        avatar: {
          url: require("../assets/public/images/profile.png"),
          size: "32",
        },
        content: {
          header:
            "Whew, so much to learn! We know this stuff can be confusing, that’s why we’re here to help. " +
            "Check out the resources below, and remember, you can click on me to ask a question any time you’re stuck.",
          footer: "",
        },
      },
      calendarProps: {
        label: "SAT/ACT CALENDAR",
        items: [
          {
            date: "Oct 3",
            label: "March SAT Late Registration Role",
          },
          {
            date: "Oct 5",
            label: "April ACT Late Registration Date",
          },
        ],
        menuItems: [
          {
            id: 0,
            label: "View completed",
          },
          {
            id: 1,
            label: "Choose school year",
          },
        ],
        buttons: [
          {
            id: 0,
            label: "Freshman",
          },
          {
            id: 1,
            label: "Sophomore",
          },
          {
            id: 2,
            label: "Junior",
          },
          {
            id: 3,
            label: "Senior",
          },
        ],
      },
      infoBoxProps: {
        video_id: "3nccfSTEUXY",
      },
      glossaryProps: {
        title: "Glossary of Terms",
        alphabets: [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
        ],
        limit: 5,
        items: [
          {
            title: "FSA ID",
            description:
              "In order to complete the FAFSA, you will need to create an FSA ID, which consists of a " +
              "username and password. The FSA ID allows students and parents to identify themselves " +
              "electronically, and to access certain US Dept. of Education websites.<br/><br/>" +
              "Your FSA ID confirms your identity when you access your financial aid information online, " +
              "or electronically sign the FAFSA, or other documents.",
          },
          {
            title: "Custodial parent",
            description:
              "The custodial parent is the parent whom a child spends the majority (over 50%) of " +
              "his/her time with. The custodial parent usually, but not always, has legal custody over the " +
              "child. In a situation with a custodial parent, the other parent is referred to as the " +
              "non-custodial parent.",
          },
          {
            title: "Dislocated worker",
            description:
              "A dislocated worker is someone who has been laid off from their job and is unlikely " +
              "to return to their previous occupation. Those who are self-employed and currently unemployed " +
              "due to an economic or natural disaster also may qualify. There are also special rules for those " +
              "in the Armed Forces. The fafsa.gov website has specific definitions of dislocated workers " +
              'for both <a href="#">parents</a> and <a href="#">spouses</a>.',
          },
          {
            title: "Expected financial contribution (EFC)",
            description:
              "Expected financial contribution is how much money the family is expected to contribute " +
              "towards the cost of college, based on the family’s financial status. In general, the stronger " +
              "a family is financially, the higher the expected financial contribution.<br/><br/>" +
              "It is calculated based on the information reported on the FAFSA. The EFC is found on the " +
              "Student Aid Report (SAR), which is mailed to you after the FAFSA is submitted.",
          },
          {
            title: "Award Letter",
            description:
              "Your award letter, or financial aid package, is a formal offer from a college or " +
              "university that defines the amount of financial aid the school is willing to give you if you " +
              "attend. It specifies the amount of financial aid that will come from grants, loans, " +
              "scholarships, and work-study programs.<br/><br/>FAID reveals this information  before " +
              "you receive your official award letter. To simplify matters, we include scholarships under " +
              "grants, and include work-study loans under loans.",
          },
          {
            title: "FAFSA",
            description:
              "The FAFSA, or the Free Application for Federal Student Aid, is a form that the US " +
              "government uses to determine your eligibility for student financial aid. Nearly every " +
              "student is eligible for some form of financial aid, so everyone should fill out the FAFSA. " +
              "At FAID, we guide you through the process and submit the FAFSA on your behalf (though " +
              "you need to sign it using your FSA ID). The FAFSA is different than the CSS Profile, " +
              "which is also required by some colleges.",
          },
          {
            title: "Legal Guardian",
            description:
              "A legal guardian is someone who has been legally appointed by a court to oversee you. " +
              "It does not include your parents.<br/><br/><a href='#'>Find out more information on guardianship</a>.",
          },
          {
            title: "CSS Profile",
            description:
              "The CSS Profile is an online application for private, non-federal student financial " +
              "aid. This is opposed to the FAFSA, which helps you qualify for public government aid, " +
              "including federal grants, state grants, and student loans.<br/><br/>It is used by about " +
              "400 colleges, universities, and professional schools across the United States. Not every c" +
              "ollege requires it, but many do. It’s a good idea to complete the CSS Profile even if only " +
              "one college you want to apply to requires it. See the list of colleges that require it.",
          },
        ],
      },
      showMoreLabel: "Show More Terms",
    },
  },
  application: {
    items: surveyWithSteps,
    apiCalls: [
      {
        component: "Organisms > ApplicationForm",
        name: "mutation saveFinancialAidApplication",
        description: "use this mutation to save this form",
        params: {
          id: "${APPLICATION_ID}",
        },
        fields: {
          user: {
            id: "${LOGGED_IN_USERID}",
          },
        },
      },
    ],
  },
  application_sai: {
    items: surveyWithStepsForSAI,
    apiCalls: [
      {
        component: "Organisms > ApplicationSAIForm",
        name: "mutation saveFinancialAidApplication",
        description: "use this mutation to save this form",
        params: {
          id: "${APPLICATION_ID}",
        },
        fields: {
          user: {
            id: "${LOGGED_IN_USERID}",
          },
        },
      },
    ],
  },
  home: {
    header,
    subHeader: {
      title: "The Blueprint for Free College In California.",
      description:
        "Let's see which Colleges will give you the most money. 7 out of 10 students qualify for free college.",
      description1: "Join 37,500+ students and sign up now.",
      btnText: "Sign Up now",
      action: () => console.log(""),
    },
    cardsContent: {
      title:
        "We make partnering easy. We are a proud partner of school districts, private schools, and organizations",
      cards: [
        {
          title: "Counselor Support",
          description:
            "We offer First Class Financial Aid support for counselors with one on one office hours, group workshops, and online courses.",
          logoType: "woman",
        },
        {
          title: "Financial Aid Software",
          description:
            "Best in class software to build a college list with the goal of graduating with little to no debt.",
          logoType: "man",
        },
        {
          title: "Financial Aid Courses",
          description:
            "Our courses walk you through how to maximize your financial aid in free money and teach you strategies based on your personal situation.",
          logoType: "woman",
        },
      ],
    },
    lottieContent: [
      {
        title:
          "What if you could see your Financial Aid package before applying to college?",
        descriptions: [
          "The traditional college financial aid timeline is backwards. First, students submit their applications and then apply for financial aid. Months later, they receive acceptance letters & award packages.",
          "It's been the same for decades. But not anymore",
        ],
        logo,
        contentPosition: "left",
      },
      {
        title:
          "With FAID, we show applicants their Financial Aid package first.",
        descriptions: [
          "We match students with colleges and universities that will give them most money and calculate their total cost to attend BEFORE they apply.",
          "Don't apply to college blind. Get started now.",
        ],
        logo: logo1,
        contentPosition: "right",
      },
    ],
    videoContent: {
      title: "See how it works",
      description:
        "Watch the short video below to learn how FAID can help you access FREE MONEY. Our product has been updated with improvements so some features may be different.",
      video_id: "3nccfSTEUXY",
    },
    bannerContent: {
      title: "Your path to an affordable college starts here.",
      btnText: "Start here",
      cards: [
        {
          title: "95%",
          description:
            "Increase in students immediately attending college after high school graduation.",
        },
        {
          title: "Over 15 million",
          description: "In total grants, scholarships & savings uncovered",
        },
      ],
    },
    testimonial: {
      cards: [
        {
          message:
            '"My students often feel the system is stacked against them. I think it\'s great they can see that college can be affordable for them, or even free."',
          user: {
            name: "Bonnie Rubin",
            role: "Counseior, West Hills, CA",
            image: "testimonial1.png",
          },
          position: "top",
        },
        {
          message:
            '"Students are swimming in a sea of confusion when it comes to college selection, applications and funding. FAID is the missing link. Students of all backgrounds finally have an engaging, informative resource that helps them tackle the challenge of college preparation, applications and financial aid."',
          user: {
            name: "Aaron Ferguson",
            role: "2017 Milken Educator Award winner, Oxnard, CA",
            image: "testimonial2.png",
          },
          position: "left",
        },
        {
          message:
            '"I don\'t feel scared about applying to college because I can see it is within reach. I wish I knew about this earlier!"',
          user: {
            name: "Santiago",
            role: "Student, Oxnard, CA",
            image: "testimonial3.png",
          },
          position: "top",
        },
      ],
    },
    subFooter: {
      title: "College Planning in your Pocket",
      description:
        "Take FAID wherever you go. FAID is a mobile friendly platform giving you college planning in your pocket.",
      btnText: "Start here",
      formTitle: "Have questions? Drop us a line",
      forms: [
        {
          label: "EMAIL ADDRESS",
          type: "email",
          value: "",
          name: "email",
        },
        {
          label: "I AM A...",
          type: "select",
          options: [
            { name: "Student", value: "Student" },
            { name: "Parent", value: "Parent" },
            { name: "Teacher", value: "Teacher" },
            { name: "School Administrator", value: "School Administrator" },
            { name: "Other", value: "Other" },
          ],
          value: null,
          name: "role",
        },
        {
          label: "MESSAGE",
          type: "textarea",
          value: "",
          name: "message",
        },
      ],
      formBtnText: "Send",
    },
    footer,
  },
  about: {
    header,
    footer,
    contentParams: {
      title: "Our Mission",
      description:
        "Our mission at FAID is to increase the accessibility of higher education to diverse communities by connecting students with institutions that give the most FREE MONEY to graduate DEBT FREE.",
      tabs: [
        { name: "For parents", href: "#", current: true },
        { name: "For students", href: "#", current: false },
        { name: "For counselors", href: "#", current: false },
        { name: "For superintendents", href: "#", current: false },
      ],
      tabItems: {
        "For parents": [
          `We know it's hard to stay involved with your child's financial aid and college applications. The process is confusing and teenagers aren't always eager to share.`,
          `With FAID, you’ll see how much financial aid your family is eligible for before it’s time to apply to college. We also walk you through the FAFSA, and provide a centralized space to organize your child’s application materials.`,
          `This allows you to stay informed during the application process, and make the best financial decisions for you and your child.`,
        ],
        "For students": [
          `If you're a high school student, we don't need to tell you - applying to college is confusing & stressful. And for most, the biggest problem with applying to college is understanding the cost to attend.`,
          `With FAID, you can see expected financial aid packages right from the start. We show you which colleges will give you the most free money, before you even apply.`,
          `We'll also walk you through the FAFSA, submit it on your behalf, and help you stay on track during the whole college application process. Your FAID Dashboard is a convenient college prep organizer that you can access from anywhere.`,
          `Your Dashboard is chock full of resources to make your college application experience simple & painless - and if you get confused, help is always just a click (or tap) away.`,
        ],
        "For counselors": [
          `Far too many students don't apply to college because they think they won't get accepted, or that they can’t afford it. Your goal is to show them opportunities they didn't know existed, and FAID can help.`,
          `Our platform allows you to see which colleges your students are considering and how much financial aid those colleges will give them. You can also see their grades, test scores, and A-G requirements - all in one place.`,
          `Message your students in bulk directly through our system, export reports, and help them find an affordable college that is right for them.`,
        ],
        "For superintendents": [
          `We know that college application & acceptance rates need improvement. . Far too many students don't apply for financial aid, let alone college.`,
          `FAID helps fix this problem. School districts that use FAID will see an increase in their students’ college applications and financial aid awards.`,
          `Through the new FAID Admin Center, we show you in-depth analytics on how your district is performing. Get a bird's eye view of your whole district, or dive deep into a particular school. Benchmark against other schools in the state, and see how your performance has changed over time.`,
          `We even visualize leading indicators of future student activity, including grades, test scores, and A-G requirements. This allows you to prevent problems before they happen, and ensure your district not only outperforms now, but continues to improve in future.`,
        ],
      },
    },
    history: {
      title: "Our History",
      descriptions: [
        "FAID is a powerful educational platform that leads students, parents, counselors, and superintendents together in one network. We have educated thousands of high school students on how to uncover opportunities for financial aid that they didn’t know existed while facilitating them through the college application process with transparency.",
        "We know that not all communities have access to the same level of education, but we ensure that every community has access to the same financial aid information. FAID allows our users to make the best decision about their future.",
      ],
    },
    coreParams: {
      title: "Our Core Values",
      contents: [
        {
          title: "Information is power",
          description:
            "Deciding which college to attend is one of the most important & financially significant decisions in a family lifetime. The information you need to make the right decision is buried deep across hundreds of online & offline sources. We believe that when this information is liberated, everyone wins. In many cases, students could have most or even all of their costs covered, which greatly affects their decision about which schools to apply to!",
          contentPosition: "left",
          image: require("../assets/public/images/core-value-1.png"),
        },
        {
          title: "Transparency is key",
          description:
            "The college financial aid process is murky & confusing. We believe there needs to be transparency in both the financial aid and college application process. In a nutshell, our goal is to make all of the information out there available and easier to understand for everyone.",
          contentPosition: "right",
          image: require("../assets/public/images/core-value-2.png"),
        },
        {
          title: "College should be affordable for everyone",
          description:
            "Student debt in America is out of control and getting worse every year. Many students don’t even apply to college because they are afraid they won’t get in, or that they can’t afford it. By showing all students how affordable college can truly be, we increase application and acceptance rates, and get more students the education they deserve at a cost they can afford!",
          contentPosition: "left",
          image: require("../assets/public/images/core-value-3.png"),
        },
      ],
    },
  },
  pricing: {
    subscriptionCards: [
      {
        mainTitle: "FOR INDIVIDUAL USERS",
        title: "1 YEAR SUBSCRIPTION",
        price: "1,999",
        items: [
          {
            title: "Software",
            data: [
              "See all the Free Money you qualify for",
              "See all the Colleges that will offer you Free Money and How Much",
              "Best-in-class software on need-based financial aid to build your college list with a Money Mindset",
            ],
          },
          {
            title: "Courses",
            data: [
              "We walk your family through how to maximize their Free Money to graduate with the least amount of debt possible",
              "We teach your family strategies for different family dynamics to maximize their need-based financial aid",
            ],
          },
        ],
        btnText: "Subscribe Now",
        registerUrl: "#",
      },
      {
        mainTitle: "FOR PUBLIC SCHOOL DISTRICTS & PRIVATE SCHOOLS",
        title: `CONTACT FOR PRICING`,
        items: [
          {
            title:
              "First-class financial aid training and support for your counselors",
            data: [
              "One on one office hours",
              "Group trainings",
              "Financial Aid Courses teaching the basics and advanced strategies on how students can graduate with the least amount of debt possible",
              "Over 65% of students every year could go to College for Free and now your counselors will know how to guide them properly",
            ],
          },
          {
            title: "Software",
            data: [
              "Students can see all the Free Money they qualify for",
              "Students will see all the Colleges that will offer them the most Free Money and How Much",
              "Best-in-class software on need-based financial aid for students to build their college list with a Money Mindset",
            ],
          },
          {
            title: "Courses",
            data: [
              "We walk your family through how to maximize their Free Money to graduate with the least amount of debt possible",
              "We teach your family strategies for different family dynamics to maximize their need-based financial aid",
              "Easy per student per year cost",
              "Multi-year contract discounts available",
            ],
          },
        ],
        btnText: "Contact",
        registerUrl: "#",
      },
    ],
  },
};
