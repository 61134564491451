import gql from 'graphql-tag';
import { financialApplicationFragment } from './fragments';

export const saveFinancialApplication = gql`
  mutation saveFinancialApplication($data: FinancialApplicationUpdateInput!, $where: FinancialApplicationWhereUniqueInput) {
    saveFinancialApplication(data: $data, where: $where) ${financialApplicationFragment}
  }
`;

export const deleteFinancialApplication = gql`
  mutation deleteFinancialApplication($where: FinancialApplicationWhereUniqueInput) {
    deleteFinancialApplication(where: $where) ${financialApplicationFragment}
  }
`;
