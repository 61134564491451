import {keyBy, isEmpty} from 'lodash';

/*
*
*/
export const getTotalFinancialApplications = async ({state, effects}) => {
  const {financialApplications} = await effects.gql.queries.financialApplications();

  state.financialApplication.totalRecords = financialApplications ? financialApplications.length : 0;
};

/*
*
*/
export const getFinancialApplications = async ({state, effects}, data) => {
  console.log('action getFinancialApplications...');

  try {

    let options = {};

    if (isEmpty(data)) {
      options = {
        first: state.financialApplication.financialApplicationPerPage,
        skip: (state.financialApplication.activePage - 1) * state.financialApplication.financialApplicationPerPage,
      };
    } else {
      options = data;
      if (!data.first) {
        options.first = state.financialApplication.financialApplicationPerPage;
      }
      if (!data.skip) {
        options.skip = (state.financialApplication.activePage - 1) * state.financialApplication.financialApplicationPerPage;
      }
    }

    console.log(options, 'getFinancialApplications options');

    const {financialApplications} = await effects.gql.queries.financialApplications(options);

    console.log(financialApplications, 'getFinancialApplications results');

    state.financialApplication.financialApplications = keyBy(financialApplications, 'id');

  } catch (e) {
    console.log(e, 'getFinancialApplications errors');
    // actions.alert.showError({ message: e.response.errors[0]['message'], title: 'Fetching Order' });
  }
};

/*
*
*/
export const saveFinancialApplication = async ({effects}, data) => {
  return await effects.gql.mutations.saveFinancialApplication(data);
};

export const getFinancialApplicationById = async ({effects}, data) => {
  const {financialApplications} = await effects.gql.queries.financialApplications(data);
  return financialApplications[0]
};

/*
*
*/
export const onChangePage = async ({state}, page) => {
  state.financialApplication.activePage = page;
};

/*
*
*/
export const onFinancialApplicationAdded = ({state}, data) => {
  state.financialApplication.push(data);
};
