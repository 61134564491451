import { createRouter, createWebHashHistory } from 'vue-router'
import * as actions from "@/store/actions";
import * as effects from '@/store/effects'
import * as state from '@/store/state'

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/pages/Home"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/pages/About"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/pages/Login"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/pages/Logout"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/pages/Register"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/pages/Terms"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/pages/PrivacyPolicy"),
  },
  {
    path: "/ssl-classlink",
    name: "SSL ClassLink",
    component: () => import("@/pages/SSLClassLink"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/pages/Dashboard"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/application",
    name: "Application",
    component: () => import("@/pages/Application"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/application-sai",
    name: "Application SAI",
    component: () => import("@/pages/ApplicationSAI"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () => import("@/pages/Pricing"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/add-payment",
    name: "Add Payment",
    component: () => import("@/pages/AddPayment"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/order-success",
    name: "Order Success",
    component: () => import("@/pages/OrderSuccess"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: () => import("@/pages/ResetPassword"),
  },
  {
    path: "/reset-password-email-sent",
    name: "Reset Password Success",
    component: () => import("@/pages/ResetPasswordSuccess"),
  },
  {
    path: "/reset-password/:token/:email",
    name: "New Password",
    component: () => import("@/pages/NewPassword"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true;
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'Home' || to.name === 'Pricing' || to.name === 'About' || to.name === 'PrivacyPolicy' || to.name === 'Terms') {
    next();
    return false;
  }
  // if (to.name === 'Dashboard') {
  //   next()
  // }
  const IsItABackButton = window.popStateDetected
  window.popStateDetected = false
  const derivedState = (from && from.matched && from.matched[0] && from.matched[0]['instances'] && from.matched[0]['instances']['default']) ? from.matched[0]['instances']['default']['state'] : state;
  const homeRoute = 'Dashboard';
  const loginRoute = 'Login';
  if (to.matched.some(routeRecord => routeRecord.meta.requiresAuth)) {
    if (!derivedState.currentUser?.id) {
      const { token, userId } = await actions.getStoredAuthToken();
      await actions.loginWithToken({ state, effects, actions }, { token, userId });
      // await actions.setStoredAuthToken();
    }
    if (!derivedState.isLoggedIn) {
      next({ name: loginRoute })
    } else next()
  } else {
    if (!derivedState.isLoggedIn) {
      const { token, userId } = await actions.getStoredAuthToken();
      await actions.loginWithToken({ state, effects, actions }, { token, userId });
    }
    if (derivedState.isLoggedIn) {
      const derivedActions = (from && from.matched && from.matched[0] && from.matched[0]['instances'] && from.matched[0]['instances']['default']) && from.matched[0]['instances']['default']['actions']
      if (derivedActions && IsItABackButton) {
        window.history.go(-1)
        derivedActions.alert.showError({ message: 'You\'re already logged in. You should logout first!' })
      } else {
        next({ name: homeRoute });
      }
    } else next();
  }
});

export default router
