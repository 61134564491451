import gql from 'graphql-tag';

export const mediaFragment = gql`
{
    id
    name
    nameLower
    caption
    type
    socialPlatform
    metadata
    description
    avatar
    source
    impressions
    approval
    visibility
    size
}
`

export const mediaCollectionFragment = gql`
{
    id
    name
    nameLower
    type
    description
    subCollections {id name}
    members {id order  member${mediaFragment}}
    metadata
    createdAt
}
`
