import gql from 'graphql-tag';
import { cartFragment } from "@/store/effects/gql/cart/fragments";

export const orderFragment = gql`{
  id metadata
  paymentMethod {
    id methodType vendor type cardType last4 image
  }
  notes
  user {
		id
		balance
		appVersion
		needToUpgrade
		timezoneOffset
		timezone
		bio
		chatId
		playerId
		username
		email
		fullName
		fullNameLower
		title
		avatar
		braintreeCustomerId
		firstName
		firstNameLower
		middleName
		middleNameLower
		lastName
		lastNameLower
		dateOfBirth
		gender
		createdAt
		updatedAt
	}
	driver {
		id
		balance
		appVersion
		needToUpgrade
		timezoneOffset
		timezone
		bio
		chatId
		playerId
		username
		email
		fullName
		fullNameLower
		title
		avatar
		braintreeCustomerId
		firstName
		firstNameLower
		middleName
		middleNameLower
		lastName
		lastNameLower
		dateOfBirth
		gender
		createdAt
		updatedAt
	}
	pickUpFrom {
		id
		name
		nameLower
		description
		slug
		rating
		address
		address2
		city
		state
		postalCode
		country
		likeCount
		typeName
		geoFenceRadius
		isActive
		servesAlcohol
		isCommercial
		isFranchise
		taxRate
		avatar
		visits
		uniqueVisits
		inDevelopment
		googlePlacesId
		
		sortOrder
		createdAt
		updatedAt
	}
	deliverTo {
		id
		name
		nameLower
		description
		slug
		rating
		address
		address2
		city
		state
		postalCode
		country
		likeCount
		typeName
		geoFenceRadius
		isActive
		servesAlcohol
		isCommercial
		isFranchise
		taxRate
		avatar
		visits
		uniqueVisits
		inDevelopment
		googlePlacesId
		
		sortOrder
		createdAt
		updatedAt
		gps { lon lat }
	}
	event {
		id
		name
		description
		isLocked
		isCancelled
		isPostponed
		avatar
		shareCount
		likeCount		
		createdAt
		updatedAt
		days { id name startDate maxBudgetPer }
	}
	company {
		id
		name
		nameLower
		slug
		entityType
		phones {
			id
			number
			verificationCode
			verified
			type
			isDefault
		}
		startDate
		domicileCountry
		domicileState
		domicileDate
		avatar
		isVerified
		isFranchise
		isBrand
		isPublicallyTraded
		tickerSymbol
	}
	cart ${cartFragment}
	items {
		id
		name
		description
		modifiers { id name sortOrder
    }
		product {
			id
			name
			nameLower
			description
			slug
			symbol
			posItemId
			likeCount
			rating
			sortOrder
			showOnHHMenu
			isHidden
			isActive
			isAlcohol
			isFood
			isAddOn
			isTaxable
			isRefundable
			isEticket
			onSale
			isFree
			createdAt
			updatedAt
      images { id name url width height source }
      pricing{id retailPrice type}
			tasks { id ingredient{id name}
      }
		}
		user {
			id
			balance
			appVersion
			needToUpgrade
			timezoneOffset
			timezone
			bio
			chatId
			playerId
			username
			email
			fullName
			fullNameLower
			title
			avatar
			braintreeCustomerId
			firstName
			firstNameLower
			middleName
			middleNameLower
			lastName
			lastNameLower
			dateOfBirth
			gender
			createdAt
			updatedAt
		}
		cart {
			id
			subtotal
			tipPercentage
			tip
			tax
			total
			discount
			delivery
			shipping
			isPending
			createdAt
			updatedAt
		}
		deliverBy
		quantity
		discountType
		discountAmount
		priceEach
		subtotal
		tax
		total
		discount
		delivery
		shipping
		isReviewed
		rating
		sortOrder
		createdAt
		updatedAt
	}
	subtotal
	tipPercentage
	tip
	tax
	total
	discount
	delivery
	shipping
	sortOrder
	transactions {
		id
		paymentMethodToken
		amountPaid
		transactionId
		walletAmount
		note
		createdAt
	}
	useWallet
	isEvent
	isCancelledByCustomer
	isCancelledByOperator
	isArchived
	isPaid
	isDelivered
	isDelivery
	isReturned
	isReadyForDelivery
	isOnTheWay
	isBeingPrepared
	isReviewed
	deliverBy
	assignedAt
	pickUpBy
	pickedUpAt
	preparedAt
	shipDate
	deliveredAt
	cancelledAt
	comments {
		id
		order {
			id
			user {
			id
			balance
			appVersion
			needToUpgrade
			timezoneOffset
			timezone
			bio
			chatId
			playerId
			username
			email
			fullName
			fullNameLower
			title
			avatar
			braintreeCustomerId
			firstName
			firstNameLower
			middleName
			middleNameLower
			lastName
			lastNameLower
			dateOfBirth
			gender
			createdAt
			updatedAt
		}
			driver {
			id
			balance
			appVersion
			needToUpgrade
			timezoneOffset
			timezone
			bio
			chatId
			playerId
			username
			email
			fullName
			fullNameLower
			title
			avatar
			braintreeCustomerId
			firstName
			firstNameLower
			middleName
			middleNameLower
			lastName
			lastNameLower
			dateOfBirth
			gender
			createdAt
			updatedAt
		}
			pickUpFrom {
			id
			name
			nameLower
			description
			slug
			rating
			address
			address2
			city
			state
			postalCode
			country
			likeCount
			typeName
			geoFenceRadius
			isActive
			servesAlcohol
			isCommercial
			isFranchise
			taxRate
			avatar
			visits
			uniqueVisits
			inDevelopment
			googlePlacesId
			
			sortOrder
			createdAt
			updatedAt
		}
			deliverTo {
			id
			name
			nameLower
			description
			slug
			rating
			address
			address2
			city
			state
			postalCode
			country
			likeCount
			typeName
			geoFenceRadius
			isActive
			servesAlcohol
			isCommercial
			isFranchise
			taxRate
			avatar
			visits
			uniqueVisits
			inDevelopment
			googlePlacesId
			phones{id number}
			sortOrder
			createdAt
			updatedAt
		}
			subtotal
			tipPercentage
			tip
			tax
			total
			discount
			delivery
			shipping
			sortOrder
			useWallet
			isEvent
			isCancelledByCustomer
			isCancelledByOperator
			isArchived
			isPaid
			isDelivered
			isDelivery
			isReturned
			isReadyForDelivery
			isOnTheWay
			isBeingPrepared
			isReviewed
			deliverBy
			assignedAt
			pickUpBy
			pickedUpAt
			preparedAt
			shipDate
			deliveredAt
			cancelledAt
			studentName
			createdAt
			updatedAt
		}
		item {
			id
			name
			description
			product {
			id
			name
			nameLower
			description
			slug
			symbol
			posItemId
			likeCount
			rating
			sortOrder
			showOnHHMenu
			isHidden
			isActive
			isAlcohol
			isFood
			isAddOn
			isTaxable
			isRefundable
			isEticket
			onSale
			isFree
			createdAt
			updatedAt
		}
			deliverBy
			quantity
			discountType
			discountAmount
			priceEach
			subtotal
			tax
			total
			discount
			delivery
			shipping
			isReviewed
			rating
			sortOrder
			createdAt
			updatedAt
		}
		product {
			id
			name
			nameLower
			description
			slug
			symbol
			posItemId
			likeCount
			rating
			sortOrder
			showOnHHMenu
			isHidden
			isActive
			isAlcohol
			isFood
			isAddOn
			isTaxable
			isRefundable
			isEticket
			onSale
			isFree
			createdAt
			updatedAt
		}
		notification {
			id
			title
			description
			likeCount
			toAll
			rating
			read
			createdAt
		}
		user {
			id
			balance
			appVersion
			needToUpgrade
			timezoneOffset
			timezone
			bio
			chatId
			playerId
			username
			email
			fullName
			fullNameLower
			title
			avatar
			braintreeCustomerId
			firstName
			firstNameLower
			middleName
			middleNameLower
			lastName
			lastNameLower
			dateOfBirth
			gender
			createdAt
			updatedAt
		}
		likeCount
		rating
		subject
		message
		createdAt
	}
	studentName
}`
