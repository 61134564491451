<template>
  <component
    :is="isCustomIcon?name:isOutline? heroOutlineIcons[name]:heroIcons[name]"
    :key="key"
    v-if="isLoaded"
    :class="`h-${size} w-${size} text-${color} hover:text-${hoverTextColor} ${customClass}`"
    :aria-hidden="ariaHidden"
  />
</template>

<script>
/**
- Use an icon with button or as it is.
- isOutline is used to switch between outline and solid icons
**/

import * as heroIcons from "@heroicons/vue/solid";
import * as heroOutlineIcons from "@heroicons/vue/outline";

export default {
  props: {
    /**
     name of icon to use from hero icons
    */
    name: {
      type: String,
      default: null
    },
    key: {
      type: String,
      default: ""
    },
    /**
    sets width and height of an icon. Default is 5
    */
    size: {
      type: String,
      default: '5'
    },
    /**
    Custom class is used to add custom classes like padding margins etc
    */
    customClass: {
      type: String,
      default: ''
    },
    /**
    color of icon. default is black
    */
    color: {
      type: String,
      default: 'black'
    },
    /**
    isOutline is used to switch between outline and solid icons
    */
    isOutline: {
      type: Boolean,
      default: false
    },
    /**
    for providing onHover color
    */
    hoverTextColor: {
      type: String,
      default: ''
    },
    /**
    for Custom Icons defalut false
    */
    isCustomIcon: {
      type: Boolean,
      default: false
    },

    /**
     * for arialhidden default false
     */

    ariaHidden: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {
      isLoaded: false,
      heroIcons: heroIcons,
      heroOutlineIcons: heroOutlineIcons
    };
  },
  mounted() {
    this.isLoaded = true;
  },
};
</script>
