export const surveyWithSteps = [
  {
    id: "Step 1",
    name: "Student Information",
    status: "current",
    questions: [
      {
        id: "5f8524162ab79c0007538765",
        group: "Student Information",
        question: "What is your birthday?",
        helpBlurb: `This one’s easy! Make sure to write your birthday in the MMDDYYYY format. For example, if your birthday is May 3, 1980, enter 05031980.<br><br>This birthday should match the one on your Social Security card.`,
        category: "Student Information",
        categoryHelpText: "",
        inputType: "TEXT",
        code: "ODR1",
        value: "01/01/1990",
      },
      {
        id: "5f8524162ab79c00075387636",
        group: "Student Information",
        question: "WHAT YEAR OF HIGH SCHOOL ARE YOU IN?",
        helpBlurb: `You need a 3.0 to qualify for the the Cal Grant. However, if you go to a community college, you only need a 2.4 to qualify for the Cal Grant.`,
        category: "Student Information",
        categoryHelpText: "",
        inputType: "RADIO_GRID",
        options:
          '["Freshman (1st year)", "Sophomore (2nd year)", "Junior (3rd year)", "Senior (4th year)"]',
        code: "ODR1",
      },
      {
        id: "5f8524162ab79c00075387627",
        group: "Student Information",
        question: "WHAT IS YOUR STATE OF LEGAL RESIDENCE?",
        helpBlurb: `The technical term “state of legal residence” can be confusing. It means your fixed permanent home. This is typically the state you grew up in and lived in the longest. If you’ve moved to a new state just for school, don’t use that state.<br><br>If your legal residence is outside of the U.S. select foreign country.<br><br>Each state decides “legal residency” differently, so if you have questions, let me know.`,
        category: "Student Information",
        categoryHelpText: "",
        inputType: "SELECT",
        options: '["California"]',
        code: "ODR1",
      },
      {
        id: "5f8524162ab79c00075387618",
        group: "Student Information",
        question:
          "WHAT IS YOUR CURRENT BALANCE OF CASH, CHECKING, AND SAVINGS ACCOUNTS?",
        helpBlurb: `Do you have a bank account? If the answer is yes, add the balance of any checking, savings, and/or any cash accounts you (and your spouse) have.
        <br><br>
        If you don’t know what a money market or stock market account is, you probably don’t have one. But if you do, include the balance of those as well.
        <br><br>
        If the balance of all these accounts is negative enter zero.`,
        category: "Student Information",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
      },
      {
        id: "5f8524162ab79c0007538769",
        group: "Student Information",
        question: "DID YOU HAVE A JOB IN 2022?",
        helpBlurb: `If you didn’t have a job, select no. If you did - but got paid in cash, also select no.
        <br><br>
        Only select yes if you had a job where your income was not in cash and was reported to the IRS.`,
        category: "Student Information",
        categoryHelpText: "",
        inputType: "BOOLEAN_SELECT",
        code: "ODR1",
      },
      {
        id: "5f8524162ab79c00075387170",
        group: "Student Information",
        question: "HOW MUCH MONEY DID YOU EARN FROM WORKING IN 2022?",
        helpBlurb: `If you did have a job, than you did taxes right?! So, you can find the answer to this question by checking your IRS tax form.
        <br><br>
        If you filed an IRS Form 1040, use Lines 7 + 12 + 18 + Box 14 [Code A] of IRS Schedule K-1 (Form 1065). If any of these values are negative, just use the number 0.
        <br><br>
        If you filed an IRS Form 1040A, use Line 7.
        <br><br>
        If you filed an IRS Form 1040EZ, use Line 1.
        <br><br>
        Guess what? You can earn up to $6,570 per year without it affecting your financial aid.`,
        category: "Student Information",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
      },
    ],
  },
  {
    id: "Step 2",
    name: "Household Information",
    status: "pending",
    questions: [
      {
        id: "5f8524162ab79c0007538760",
        group: "Household Information",
        question: "HOW MANY PARENTS CONTRIBUTE TO YOUR FAMILY'S EXPENSES?",
        helpBlurb: `If both your parents have jobs and work to pay for things like groceries and bills select yes here.
        <br><br>
        If only one parent works, select no. Or, if your parents are divorced, unmarried, or widowed, and you only live with one parent, select no.`,
        category: "Household Information",
        categoryHelpText: "",
        inputType: "RADIO_GRID",
        options:
          '["1 Parent, 1 Income", "2 Parents, 1 Income", "2 Parents, 2 Incomes"]',
        code: "ODR1",
      },
      {
        id: "5f8524162ab79c000753876901",
        group: "Household Information",
        question: "HOW MANY PEOPLE ARE IN YOUR HOUSEHOLD?",
        helpBlurb: `Hmm, who’s considered a part of your household? Here's a list, don't forget anyone!
        <ul>
        <li>You and your parent(s) (and your spouse if you’re married).</li>
        <li>Anyone else you live with who doesn't pay rent to your parents. This includes your children, siblings, half-siblings, grandparents, step-parents etc.</li>
        <li>Anyone who you don’t live with, but receives more than half of their money from your parents. For example, your grandparents may live in a retirement home, but they are technically a part of your household if your parents pay their rent.</li>
        </ul>
        If your parents are divorced or were never married, you’ll only need to fill out this form with answers about one parent. Your supposed to use the parent you spend the most time with, but I recommend using the parent who has the lowest income and/or is unmarried. This will help you to get the best financial aid award possible.`,
        category: "Household Information",
        categoryHelpText:
          "Make sure to check with Ed to see who is included in your household",
        inputType: "SELECT",
        options: '["Two", "Three", "Four", "Five", "Six or more"]',
        code: "ODR1",
      },
      {
        id: "5f8524162ab79c0007538762",
        group: "Household Information",
        question:
          "HOW MANY OTHERS FROM YOUR HOUSEHOLD WILL BE IN COLLEGE AT THE SAME TIME AS YOU?",
        helpBlurb: `Will any of your siblings also be in college when you are? If so, include them!`,
        category: "Household Information",
        categoryHelpText:
          "Include anyone else in your household who will attend college when you do. If your parents will be in college, do not include them.",
        inputType: "SELECT",
        options:
          '["None", "One", "Two", "Three", "Four", "Five", "Six or more"]',
        code: "ODR1",
      },
      {
        id: "5f8524162ab79c0007538763",
        group: "Household Information",
        question: "IN WHAT YEAR WAS YOUR OLDEST PARENT BORN?",
        helpBlurb: `If you can remember which of your parents is the oldest, enter that parents birthday in the MMDDYYYY format. This date must match the date of birth on that parent's Social Security card.
        <br><br>
        For example, if your parent’s birthday is May 3, 1960, enter 05031960.`,
        category: "Household Information",
        categoryHelpText: "",
        inputType: "TEXT",
        code: "ODR1",
      },
    ],
  },
  {
    id: "Step 3",
    name: "Parent's Financial Information",
    status: "pending",
    questions: [
      {
        id: "5f8524162ab79c0007538760",
        group: "Parent's Financial Information",
        question:
          "WHICH FORM DID YOUR PARENTS USE TO FILE THEIR TAXES IN 2022?",
        helpBlurb: `You might need to ask your parent(s) to help with these questions. Ask them which income tax return they filed for in 2022.
        <br><br>
        Don't forget that if your parents are divorced or were never married you only need to use information about one parent!`,
        category: "Parent's Financial Information",
        categoryHelpText: "",
        inputType: "RADIO_GRID",
        options: '["1040", "Not Required To File"]',
        code: "ODR1",
      },
      {
        id: "5f8524162ab79c0007538761",
        group: "Parent's Financial Information",
        question: "WHAT WAS YOUR PARENTS ADJUSTED GROSS INCOME IN 2022?",
        helpBlurb: `Adjusted gross income is a fancy way of saying the money your parents earned in 2022 minus a bit they don’t need to be taxed on.
        <br><br>
        If you’re parents are married add their adjusted gross income together. Include your stepparents AGI if your parent is remarried.
        <br><br>
        This can be found on their (IRS) tax form:
        IRS Form 1040 – Line: 37
        IRS Form 1040A – Line: 21
        IRS Form 1040EZ – Line: 4
        <br><br>
        If the parents filed separate tax returns, or one parent filed and the other did not, let me know.
        <br><br>
        If you’re not sure, you can select I don’t know and enter your best guess.`,
        category: "Parent's Financial Information",
        categoryHelpText: "This is found on your parent's 2022 tax returns:",
        inputType: "PRICE",
        code: "ODR1",
      },
      // {
      //   "id": "5f8524162ab79c0007538762",
      //   "group": "Parent's Financial Information",
      //   "question": "WHAT IS YOUR BEST GUESS?",
      //   "helpBlurb": `Adjusted gross income is a fancy way of saying the money your parents earned in 2022 minus a bit they don’t need to be taxed on.
      //   <br><br>
      //   If you’re parents are married add their adjusted gross income together. Include your stepparents AGI if your parent is remarried.
      //   <br><br>
      //   This can be found on their (IRS) tax form:
      //   IRS Form 1040 – Line: 37
      //   IRS Form 1040A – Line: 21
      //   IRS Form 1040EZ – Line: 4
      //   <br><br>
      //   If the parents filed separate tax returns, or one parent filed and the other did not, let me know.
      //   <br><br>
      //   If you’re not sure, you can select I don’t know and enter your best guess.`,
      //   "category": "Parent's Financial Information",
      //   "categoryHelpText": "",
      //   "inputType": "SELECT",
      //   "options": '["Under $30,000", "From $30,000 - $49,999", "From $50,000 - $100,000", "Over $100,000"]',
      //   "inputType": "SELECT",
      //   "code": "ODR1",
      // },
      {
        id: "5f8524162ab79c0007538763",
        group: "Parent's Financial Information",
        question: "HOW MUCH INCOME TAX DID YOUR PARENTS PAY IN 2022?",
        helpBlurb: `Income tax is not your parents income. It’s actually the amount of money they paid in taxes.
        <br><br>
        Remember to add your parents' income tax together if they're married, and to include your stepparents income tax if your parent is remarried. This is the last time I'll tell you this, but you'll need to do the same for every financial question in this section.
        <br><br>
        Income tax can be found on the (IRS) tax form:
        <br><br>
        IRS Form 1040 – Subtract line 46 from line 56 and enter the total
        IRS Form 1040A – Subtract line 36 from line 28 and enter the total
        IRS Form 1040EZ – Use Line 10
        <br><br>
        You don’t need to include any commas or decimal points here. You can round up to the nearest dollar.`,
        category: "Parent's Financial Information",
        categoryHelpText: "This is found on your parent's 2022 tax returns:",
        inputType: "PRICE",
        code: "ODR1",
      },
      {
        id: "5f8524162ab79c0007538764",
        group: "Parent's Financial Information",
        question:
          "IN 2022 OR 2023, HAS ANYONE IN YOUR HOME RECEIVED BENEFITS FROM ANY DESIGNATED FEDERAL BENEFIT PROGRAMS?",
        helpBlurb: `Remember that term household from the last page? Select yes here if anyone in your household received any of the these benefits in 2022 or 2023:
        <ul>
        <li>Earned Income Credit (EIC)</li>
        <li>Federal Housing Assistance</li>
        <li>Free or Reduced Price School Lunch</li>
        <li>Medicaid</li>
        <li>Refundable Credit for Coverage Under a Qualified Health Plan (QHP)</li>
        <li>Supplemental Nutrition Assistance Program (SNAP)</li>
        <li>Supplemental Security Income (SSI)</li>
        <li>Temporary Assistance for Needy Families (TANF)</li>
        <li>Special Supplemental Nutrition Program for Women, Infants and Children (WIC)</li>
        </ul>`,
        category: "Parent's Financial Information",
        categoryHelpText: "",
        inputType: "BOOLEAN",
        code: "ODR1",
        isHelp: true,
        explanation: `Federal benefits could include any of the following:
        <ul class="list-disc list-inside ml-4 mt-3">
        <li>Earned Income Credit (EIC)</li>
        <li>Federal Housing Assistance</li>
        <li>Free or Reduced Price School Lunch</li>
        <li>Medicaid</li>
        <li>Refundable Credit for Coverage Under a Qualified Health Plan (QHP)</li>
        <li>Supplemental Nutrition Assistance Program (SNAP)</li>
        <li>Supplemental Security Income (SSI)</li>
        <li>Temporary Assistance for Needy Families (TANF)</li>
        <li>Special Supplemental Nutrition Program for Women, Infants and Children (WIC)</li>
        </ul>`,
      },
      {
        id: "5f8524162ab79c0007538764",
        group: "Parent's Financial Information",
        question: "ARE EITHER OF YOUR PARENTS A DISLOCATED WORKER?",
        helpBlurb: `Remember that term household from the last page? Select yes here if anyone in your household received any of the these benefits in 2022 or 2023:
        <ul>
        <li>Medicaid</li>
        <li>Supplemental Security Income (SSI)</li>
        <li>Supplemental Nutrition Assistance Program (SNAP)</li>
        <li>Free or Reduced Price School Lunch</li>
        <li>Temporary Assistance for Needy Families (TANF)</li>
        <li>Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)</li></ul>`,
        category: "Parent's Financial Information",
        categoryHelpText: "",
        inputType: "BOOLEAN",
        code: "ODR1",
        isHelp: true,
        explanation: `The parent may qualify as a dislocated worker if he or she meets one of the following conditions:
        <ul class="list-disc list-inside ml-4 mt-3">
        <li>He or she has been laid off or received a lay-off notice from his/her job.</li>
        <li>He or she is receiving unemployment benefits due to being laid off or losing a job and he or she is unlikely to return to a previous occupation.</li>
        <li>He or she is self-employed but is unemployed due to economic conditions or natural disaster. He or she is the spouse of an active duty member of the Armed Forces and has experienced a loss of employment because of relocating due to permanent change in duty station.</li>
        <li>He or she is the spouse of an active duty member of the Armed Forces and is unemployed or underemployed, and is experiencing difficulty in obtaining or upgrading employment.</li>
        <li>He or she is a displaced homemaker. A displaced homemaker is generally a person who previously provided unpaid services to the family (e.g., a stay-at-home mom or dad), is no longer supported by the spouse, is unemployed or underemployed, and is having trouble finding or upgrading employment.</li>
        </ul>
        `,
      },
      {
        id: "5f8524162ab79c0007538770",
        group: "Parent's Financial Information",
        question:
          "WHAT IS YOUR PARENT'S CURRENT BALANCE OF CASH, CHECKING AND SAVINGS ACCOUNTS?",
        helpText:
          "Accounts include checking, savings, money market, stock market and any accounts where the money can be cashed out without penalties. <b>Do not include retirement accounts.</b>",
        helpBlurb: ``,
        category: "Parent's Financial Information",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
      },
      {
        id: "5f8524162ab79c0007538771",
        group: "Parent's Financial Information",
        question:
          "WHAT IS THE CURRENT BALANCE YOUR PARENTS HAVE IN EDUCATIONAL ACCOUNTS",
        helpText:
          "Examples include 529 Accounts, Coverdell, UTMA, UGMA, or any other educational savings account.",
        helpBlurb: ``,
        category: "Parent's Financial Information",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
      },
      {
        id: "5f8524162ab79c00075387691",
        group: "Parent's Financial Information",
        question: "WHAT IS YOUR PHONE NUMBER?",
        helpBlurb: `Here’s an easy one! Enter your phone number. Don’t include any parentheses or dashes here. Just the number is all you need, area code first. For example, 2025551212.
        <br><br>
        Don’t worry we won’t call you! We’ll send you important text message updates, but you can always turn this feature off if you’d like.`,
        category: "Parent's Financial Information",
        categoryHelpText:
          "Optional. We use this to send you important updates via text message.",
        inputType: "TEXT",
        code: "ODR1",
      },
    ],
  },
  {
    id: "Step 4",
    name: "Summary",
    status: "pending",
  },
];

export const surveyWithStepsForSAI = [
  {
    id: "Step 1",
    name: "Parent Contribution",
    status: "current",
    questions: [
      {
        id: "5f8524162ab79c0007538765",
        group: "Parent Contribution",
        question: "Parents’ Deductible Payments to IRA/KEOGH/Other",
        helpBlurb: ``,
        category: "Parent Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab79c1007538765",
        group: "Parent Contribution",
        question: "Parents’ Tax-Exempt Interest Income",
        helpBlurb: ``,
        category: "Parent Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab79c0007538865",
        group: "Parent Contribution",
        question:
          "Parents’ Untaxed Portions of IRA Distributions (Subtract IRA rollover; if total is less than zero, enter zero)",
        helpBlurb: ``,
        category: "Parent Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab79c0007548765",
        group: "Parent Contribution",
        question:
          "Parents’ Untaxed Portions of Pensions (Subtract Pension rollover; if total is less than zero, enter zero)",
        helpBlurb: ``,
        category: "Parent Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab79c0006538765",
        group: "Parent Contribution",
        question:
          "Parents’ Foreign Income Exclusion (reported on U.S. Tax Return; if less than zero, enter the absolute value)",
        helpBlurb: ``,
        category: "Parent Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab72c0007538765",
        group: "Parent Contribution",
        question:
          "Parents’ Taxable College Grant and Scholarship Aid (reported as income)",
        helpBlurb: ``,
        category: "Parent Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab79c0007838765",
        group: "Parent Contribution",
        question: "Parents’ Education Credits",
        helpBlurb: ``,
        category: "Parent Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab79c0007438765",
        group: "Parent Contribution",
        question: "Parents’ Federal Work-study (FWS)",
        helpBlurb: ``,
        category: "Parent Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab73c0007538765",
        group: "Parent Contribution",
        question:
          "Annual child support received for the last complete calendar year",
        helpBlurb: ``,
        category: "Parent Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
    ],
  },
  {
    id: "Step 2",
    name: "Student Contribution",
    status: "pending",
    questions: [
      {
        id: "5f8524162ab79c055407538765",
        group: "Student Contribution",
        question: "Student Deductible Payments to IRA/KEOGH/Other",
        helpBlurb: ``,
        category: "Student Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab79c0004538765",
        group: "Student Contribution",
        question: "Student Tax-Exempt Interest Income",
        helpBlurb: ``,
        category: "Student Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f85241362ab79c0007538765",
        group: "Student Contribution",
        question:
          "Student Untaxed Portions of IRA Distributions (Subtract IRA rollover; if total is less than zero, enter zero)",
        helpBlurb: ``,
        category: "Student Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab79b0007538765",
        group: "Student Contribution",
        question:
          "Student Untaxed Portions of Pensions (Subtract Pension rollover; if total is less than zero, enter zero)",
        helpBlurb: ``,
        category: "Student Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162vb79c0007538765",
        group: "Student Contribution",
        question:
          "Student Foreign Income Exclusion (reported on U.S. Tax Return; if less than zero, enter the absolute value)",
        helpBlurb: ``,
        category: "Student Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab79c0007738765",
        group: "Student Contribution",
        question:
          "Student College Grant and Scholarship Aid (reported as income)",
        helpBlurb: ``,
        category: "Student Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab7cc0007538765",
        group: "Student Contribution",
        question: "Student Education Credits",
        helpBlurb: ``,
        category: "Student Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab79k0007538765",
        group: "Student Contribution",
        question: "Student Federal Work-study (FWS)",
        helpBlurb: ``,
        category: "Student Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab79c00075t8765",
        group: "Student Contribution",
        question: "Student U.S. Income Tax Paid (or Foreign Equivalent)",
        helpBlurb: ``,
        category: "Student Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
      {
        id: "5f8524162ab79c0j07538765",
        group: "Student Contribution",
        question: "Net worth of current investments",
        helpBlurb: ``,
        category: "Student Contribution",
        categoryHelpText: "",
        inputType: "PRICE",
        code: "ODR1",
        value: 0,
      },
    ],
  },
];
