<template>
  <Disclosure v-slot="{ open }" as="nav">
    <div
        class="w-full flex justify-center border-b py-4 px-4 sm:px-4 xl:px-2 bg-white mx-auto fixed top-0 lg:relative shadow lg:shadow-none z-50">
      <div class="max-w-7xl w-full flex justify-between items-center">
        <img class="h-8 w-auto cursor-pointer" :src="logo" alt="" @click="$emit('goHome')">
        <nav class="hidden lg:flex space-x-5">
          <Link v-for="(nav, i) in state.isLoggedIn ? navigation : loginNavigation" hoverColor="primary"
                :content="nav.name" :href="nav.href" size="sm"
                color="gray-500" weight="medium"/>
        </nav>
        <div class="flex lg:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
              class="p-2 rounded-md inline-flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary focus:ring-primary">
            <Icon v-if="!open" name="MenuIcon" size="6" color="gray-500" class="block hover:text-white"/>
            <Icon v-else name="XIcon" size="6" color="gray-500" class="block hover:text-white"/>
          </DisclosureButton>
        </div>
      </div>
    </div>
    <DisclosurePanel class="lg:hidden fixed z-50 top-20 w-full bg-white shadow-lg">
      <div class="px-2 pt-2 pb-3 space-y-1 flex flex-col">
        <DisclosureButton v-for="(item, i) in state.isLoggedIn ? navigation : loginNavigation" :key="i" as="div">
          <Link :content="item.name" :url="item.href" weight="medium"
                :class="['px-2 py-2 rounded-md w-full flex text-gray-600 hover:bg-primary hover:text-white']"/>
        </DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
<script>
import Link from "../../../atoms/Link/Link.vue";
import Icon from '../../../atoms/Icons/Icons.vue'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";

export default {
  components: {
    Link,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Icon,
  },
  props: {
    /**
     * Use it to show navigation
     */
    navigation: {
      type: Array,
      default: () => [],
    },
    loginNavigation: {
      type: Array,
      default: () => []
    },
    /**
     * Use it for logo
     */
    logo: {
      type: String,
      default: ''
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    }
  },
};
</script>
