<template>
  <div style="z-index: 200000 !important;" class="fixed top-4 right-0 sm:right-6 w-full sm:w-96">
    <WithDismissButton @onClose="() => hideAlert()" :type="type" v-if="visible" :title="message" :icon="type === 'success' ? 'CheckCircleIcon' : 'XCircleIcon'"/>
  </div>
</template>

<script>

import WithDismissButton from "../organisms/Alert/WithDismissButton/WithDismissButton.vue";

export default {
  components: {WithDismissButton},
  props: ['type', 'message', 'visible'],
  methods: {
    hideAlert: function () {
      this.actions.alert.hide();
    }
  },
  watch: {
    visible: function () {
      setTimeout(() => this.hideAlert(), 3000)
    }
  }
}
</script>
<style scoped>
.custom-alert {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000
}
</style>
