import gql from 'graphql-tag';

export const siteFragment = gql`{
  id
  name
  address2
  address
  city
  state
  country
  rating
  tags{id}
  postalCode
  launchDate
  avatar
  comments{
    id subject message rating
  }
  gps{
    lat
    lon
  }
  images {
      id
      url
    }
  groups {
      id
      name
  }
  sortOrder
  likes{
    id
    user{
      id
    }
  }
  products {
    id
    name
    description
    isActive
    isAddOn
    isFood
    rating
    isAlcohol
    categories
    metadata
    tags{id name type}
    likes { id
      user { id }
    }
    pricing {
      id
      retailPrice
      type
    }
    tasks {
      id
      sortOrder
      name
      description
      type
      ingredient{id name}
      size
      unit
    }
    images {
      id
      url
    }
  }
}`;
