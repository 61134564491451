import gql from 'graphql-tag';

/*
*
*/
export const wallets = gql`
  query getWalletTransactions($userId: String!) {
    getWalletTransactions(userId: $userId)
  }
`;

/*
*
*/
export const getWalletBalance = gql`
  query getWalletBalance($userId: String! $date: DateTime) {
    getWalletBalance(userId:$userId date:$date)
  }
`;
