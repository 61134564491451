export const state = {
    mediaCollections: {},
    isLoading: false,
    mediaPerPage: 10,
    totalRecords: 10,
    activePage: 1,
    mediaCollectionList: mediaNamespace =>
      Object.values(mediaNamespace.mediaCollections)
        .sort((a, b) => {
          if (a.createdAt > b.createdAt) {
            return 1
          } else if (a.createdAt < b.createdAt) {
            return -1
          }

          return 0
        })
        .slice(0, mediaNamespace.mediaPerPage)
  }