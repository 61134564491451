import gql from 'graphql-tag';
import { schoolFragment } from './fragments';

export const saveSchool = gql`
  mutation saveSchool($data: SchoolUpdateInput!, $where: SchoolWhereUniqueInput) {
    saveSchool(data: $data, where: $where) ${schoolFragment}
  }
`;

export const deleteSchool = gql`
  mutation deleteSchool($where: SchoolWhereUniqueInput) {
    deleteSchool(where: $where) ${schoolFragment}
  }
`;

export const calculateSchoolList = gql`
  mutation calculateSchoolList($appId: String, $schoolId: String) {
    calculateSchoolList(appId: $appId, schoolId: $schoolId)
  }
`