import gql from 'graphql-tag';
import { companyFragment } from './fragments';

/*
*
*/
export const saveCompany = gql`
  mutation saveCompany($data: CompanyUpdateInput!, $where: CompanyWhereUniqueInput $addProducts: [String]) {
    saveCompany(data: $data, where: $where addProducts: $addProducts) ${companyFragment}
  }
`;

/*
*
*/
export const deleteGroup = gql`
  mutation deleteGroup($where: GroupWhereUniqueInput) {
    deleteGroup(where: $where) ${companyFragment}
  }
`;
