module.exports = {
  Settings: {
    appName: "FAID",
    appVersion: "1.0",
    environment: "production",
    mainDomain: "www.faid.co",
    groupDomain: "www-faid-co",
    adminEmail: "info@zyoninc.com",
    siteId: "",
    gqlServer: {
      url: "https://api.faid.co/",
    },
    mailerServer: {
      url: "https://mailer.faid.co",
    },
    flow: "modal",
    winston: {
      level: "log",
    },
    sentry: {
      dsn: "https://8b3b39afdb4d4cc3b440ca52befc0ad9@o327848.ingest.sentry.io/4504719207235584",
      logLevel: ["error"], // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
    },
    intercomAppId: "",
    appKey:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOiJDaGV3Ym94IiwidXNlcklkIjoiNjIzMTAxYWNhZGJlMWQwMDA4YTM5OWFmIiwiaWF0IjoxNjUxMDQ4NTUyfQ.1SdS4XkT-HQp7jdx6O4PMJt2Gh9brSRtFblPsoBLVsY",
    primary: "#FEDE32",
    logo: {
      dark: {},
      light: {
        mobile: "",
        desktop: "",
      },
    },
    classLink: {
      clientId: "c16700000687053f4e2d7ab865991847ad5e733d84b9",
      clientSecret: "447732309e9b69104c6295e7f026da96",
      authURI: "https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=https%3A%2F%2Fwww.faid.co%2F%23%2Fssl-classlink&client_id=c16700000687053f4e2d7ab865991847ad5e733d84b9&response_type=code",
      redirectURI: "https://www.faid.co/#/ssl-classlink",
    },
    nextbillion: {
      api_key: "",
      api_host: "https://api.nextbillion.io",
    },
    plaid: {
      domain: "https://sandbox.plaid.com",
      key: "",
    },
    aws: {
      bucket: "faid-media",
      region: "us-east-1",
      accessKey: "AKIAZDYNAZG2UEMDPGUP",
      secretAccessKey: "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",
      accelerateUrl: "faid-media.s3-accelerate.amazonaws.com",
    },
    stripeKey:
      "pk_test_51MOtCNHZ6ociB84AruFGEuJwwteEDoqd0pJnMzWR3Foo6lpvcOgvggIHyIh4vAfbNIxk7qhVDVl3EkVTdMvh3DrW00xltyZ7GI", // pk_live_51KxzDMHdI13K6hOFz9akFnxO9WXiVkxq0RwR4alOFU8IPlZkeVOxnFtRnKvjJIpHaev8PkLjaPImor1MhidhBGM500ocWsuVzS pk_test_51KxzDMHdI13K6hOF8eqmM3zc4HKrKGaFyLOa0dl1EtO7T18ZmoqXWsoAC0TKlqFOLeKp24Du5Bi57DrxQD1RMqV5001vPcGuK5
    siteId: "6350f43124aa9a0008e73523",
  },
};
