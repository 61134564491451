<template>
  <div class="w-screen" id="container">
    <Header
      v-if="!headers.find((h) => h === $route.name)"
      v-bind="{
        ...c.header,
        isLoggedIn: state.isLoggedIn,
      }"
      @goHome="() => $router.push('/')"
    />
    <slot />
    <Footer
      v-if="!headers.find((h) => h === $route.name)"
      v-bind="{
        ...c.footer,
        isLoggedIn: state.isLoggedIn,
      }"
    />
    <Alert
      :type="state.alert.type"
      :message="state.alert.message"
      :visible="state.alert.visible"
    />
  </div>
</template>

<script>
import { c } from "@/components/constants.js";
import SmartBanner from "smart-app-banner";
import "smart-app-banner/dist/smart-app-banner.css";
import Alert from "@/components/common/Alert";
import Header from "@/components/organisms/Headers/SimpleWithNavigation/SimpleWithNavigation.vue";
import Footer from "@/components/organisms/Footers/SimpleWithSocialIcons/SimpleWithSocialIcons.vue";

export default {
  name: "Default",
  components: {
    Alert,
    Header,
    Footer,
  },
  data() {
    return {
      c,
      headers: ["Login", "Register", "Home", "About", "Terms", "PrivacyPolicy", 'Reset Password', 'Reset Password Success', 'New Password'],
    };
  },
  watch: {
    initialAddress: async function (val) {
      await this.searchGooglePlaces(val);
    },
    $route: function () {
      document.getElementById("container").scrollIntoView();
    },
  },
  methods: {},
  beforeMount() {},
  created() {
    // new SmartBanner({
    //   daysHidden: 15,
    //   daysReminder: 90,
    //   appStoreLanguage: "us",
    //   title: "Chewbox",
    //   author: "Chewbox, Inc.",
    //   button: "VIEW",
    //   store: {
    //     ios: "https://apps.apple.com/us/app/chewbox/id1453895499",
    //     android:
    //       "https://play.google.com/store/apps/details?id=com.chewbox&hl=en_US&gl=US",
    //   },
    //   price: {
    //     ios: "FREE",
    //     android: "FREE",
    //   },
    // });
  },
};
</script>

<style scoped>
.content {
  padding: 0 15px;
  min-height: 84vh;
}
</style>
