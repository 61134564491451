import gql from 'graphql-tag';

export const walletFragment = gql`{
  id
  sentTo { id firstName fullName avatar lastName }
  sentFrom { id firstName fullName avatar lastName }
  totalCost cost numberOfItems serviceFee description isHidden isAccepted isRedeemed isPending isCampaign isExpired isCancelled
  isRebate usedForGift usedForWallet usedOnOrder usedForSplit
  isCancelled
  dueToReceiver
  createdAt
  ad {id name recipeUrl textColor}
  campaign{id name title ads{id name recipeUrl}}
  dueToReceiver 
}`
